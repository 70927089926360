import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box, Typography } from "@mui/material";
import Authentication from "../../auth";
import { Space, Table } from "antd";
import EditWORequest from './edit';
import DeleteWORequest from './delete';
import SearchData from "../../global/components/FeatureTable/SearchData";
import ExportData from "../../global/components/FeatureTable/exportData";
import { ButtonAddNew } from "../../global/components/ButtonAction";
import { getWORequest } from "../Api/GetData";
import StyleContent from "../../../components/Global/StyleContent";
import StyleGridHeader from "../../../components/Global/StyleContent/StyleGrid/StyleGridHeader";
import AddAuthorityIdx from "../../../components/Global/Authority/AddAuthority";
import EditAuthorityIdx from "../../../components/Global/Authority/EditAuthority";
import DeleteAuthorityIdx from "../../../components/Global/Authority/DeleteAuthority";
import PrintAuthorityIdx from "../../../components/Global/Authority/PrintAuthority";
import DetailGrid from "../../../components/Global/Grid/DetailGrid";

const WORequest = () => {

    Authentication();

    const myAddAuthIdx = AddAuthorityIdx();
    const myEditAuthIdx = EditAuthorityIdx();
    const myDeleteAuthIdx = DeleteAuthorityIdx();
    const myPrintAuthIdx = PrintAuthorityIdx();

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState("");

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await getWORequest();
            setData(response);
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setData([]);
            }
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const columns = [
        {
            key: 'key',
            title: 'No',
            dataIndex: 'key',
            width: 80,
            fixed: 'left',
            sorter: (a, b) => a.key - b.key,
        },
        {
            title: 'Facility Name',
            dataIndex: 'FacilityName',
            sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
        },
        {
            title: 'WO Request Number',
            dataIndex: ['WORequest', 'WORNumber'],
            sorter: (a, b) => a.WORequest.WORNumber.localeCompare(b.WORequest.WORNumber),
        },
        {
            title: 'WO Request Date',
            dataIndex: ['WORequest', 'WORDate'],
            sorter: (a, b) => a.WORequest.WORDate.localeCompare(b.WORequest.WORDate),
        },

        {
            title: 'Request By',
            dataIndex: 'EmployeeName',
            sorter: (a, b) => a.EmployeeName.localeCompare(b.EmployeeName),
        },
        {
            title: 'Location Name',
            dataIndex: 'LocationName',
            sorter: (a, b) => a.LocationName.localeCompare(b.LocationName),
            render: (_, record) => {
                if (!record.LocationName) {
                    return "-";
                }
                return record.LocationName;
            }
        },
        {
            title: 'WO Type',
            dataIndex: ['WORequest', 'WOType'],
            sorter: (a, b) => a.WORequest.WOType.localeCompare(b.WORequest.WOType),
        },
        {
            title: 'WO Type Referensi',
            dataIndex: 'WOTypeRefName',
            sorter: (a, b) => a.WOTypeRefName.localeCompare(b.WOTypeRefName),
        },
        {
            title: 'Status',
            dataIndex: ['WORequest', 'AppStatus'],
            sorter: (a, b) => a.WORequest.AppStatus.localeCompare(b.WORequest.AppStatus),
            render: (_, record) => {
                if (!record.WORequest.AppStatus) {
                    return "-";
                }
                return record.WORequest.AppStatus;
            }
        },

    ];

    if (myEditAuthIdx > 0 || myDeleteAuthIdx > 0) {
        columns.push({
            title: 'Action',
            key: 'Action',
            fixed: 'right',
            width: 100,
            render: (_, record) => {
                if (
                    record.WORequest.TranStatus !== 0
                ) {
                    return null;
                }
                return (
                    <Space>
                        {myEditAuthIdx && (
                            <EditWORequest data={record} onEdit={fetchData} />
                        )}

                        {myDeleteAuthIdx && (
                            <DeleteWORequest data={record} onDelete={fetchData} />
                        )}
                    </Space>
                );
            },
        })
    }

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredData = data.filter((item) =>
        Object.values(item).some(
            (val) => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
        )
    );

    const [selectedRows, setSelectedRows] = useState([]);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
            // console.log(selectedRows);
        },
    };

    const columnsExport = columns
        .filter((item) => item.key !== "Action" && item.key !== "key")
        .map((item) => {

            if (item.title === "Active") {
                return {
                    title: item.title,
                    key: item.dataIndex,
                    render: (record) => (record.WORequest.IsSuspend ? "Inactive" : "Active"),
                };
            }

            return {
                title: item.title,
                key: item.dataIndex,
            };
        });

    const processExportData = (data) =>
        data.map((item) => ({
            ...item,
            WORequest: {
                ...item.WORequest,
                IsSuspend: item.WORequest.IsSuspend ? "Inactive" : "Active",
            }
        }));

    return (
        <StyleContent>
            <StyleGridHeader>
                <Header title="WO REQUEST" subtitle="All WO Request Data" />

                <Box display="flex" justifyContent="end" gap="8px">
                    {myPrintAuthIdx && (
                        <ExportData
                            data={processExportData(selectedRows.length > 0 ? selectedRows : data)}
                            columns={columnsExport}
                            name="WO Request"
                        />
                    )}

                    {myAddAuthIdx && (
                        <ButtonAddNew
                            link="form"
                        />
                    )}
                </Box>

            </StyleGridHeader>

            <SearchData
                filterValue={searchText}
                handleFilterChange={handleSearch}
            />

            <Table
                loading={isLoading}
                columns={columns}
                dataSource={filteredData}
                rowSelection={data.length === 0 ? null : rowSelection}
                expandable={data.length === 0 ? null : {
                    expandedRowRender: (record) => (
                        <DetailGrid>
                            <Typography variant="h5" fontWeight={600}>Description : </Typography>
                            <Typography variant="h6">{record.WORequest.Description}</Typography>
                        </DetailGrid>
                    ),
                    rowExpandable: (record) => record.WORequest.Description !== '',
                }}
                pagination={{
                    showSizeChanger: true,
                    defaultPageSize: 10,
                }}
                scroll={{ x: 2000 }}
            />

        </StyleContent>
    );
};

export default WORequest