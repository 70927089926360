import dayjs from "dayjs";

export const columnsFacility = [
    {
        key: 'FacilityCode',
        title: 'Facility Code',
        dataIndex: 'FacilityCode',
        width: 150,
        fixed: 'left',
        sorter: (a, b) => a.FacilityCode.localeCompare(b.FacilityCode),
    },
    {
        key: 'FacilityName',
        title: 'Facility Name',
        dataIndex: 'FacilityName',
        sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
    },
    {
        key: 'Address',
        title: 'Address',
        dataIndex: 'Address',
        width: 400,
        sorter: (a, b) => a.Address.localeCompare(b.Address),
    },
    {
        key: 'City',
        title: 'City',
        dataIndex: 'City',
        sorter: (a, b) => a.City.localeCompare(b.City),
    },
    {
        key: 'Country',
        title: 'Country',
        dataIndex: 'Country',
        sorter: (a, b) => a.Country.localeCompare(b.Country),
    },
    {
        key: 'Phone1',
        title: 'Phone Number 1',
        dataIndex: 'Phone1',
        sorter: (a, b) => a.Phone1.localeCompare(b.Phone1),
    },
    {
        key: 'Phone2',
        title: 'Phone Number 2',
        dataIndex: 'Phone2',
        sorter: (a, b) => a.Phone2.localeCompare(b.Phone2),
    },
    {
        key: 'Fax',
        title: 'Fax',
        dataIndex: 'Fax',
        sorter: (a, b) => a.Fax.localeCompare(b.Fax),
    },
    {
        key: 'ContactPerson',
        title: 'Contact Person',
        dataIndex: 'ContactPerson',
        sorter: (a, b) => a.ContactPerson.localeCompare(b.ContactPerson),
    },
];


export const columnsWorkOrder = [
    {
        title: 'FacilityName',
        dataIndex: 'FacilityName',
        sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
    },
    {
        title: 'WO Number',
        dataIndex: ['WOTransaction', 'WONumber'],
        sorter: (a, b) => a.WOTransaction.WONumber.localeCompare(b.WORequest.WONumber),
    },
    {
        title: 'WO Date',
        dataIndex: ['WOTransaction', 'WODate'],
        sorter: (a, b) => a.WOTransaction.WODate.localeCompare(b.WOTransaction.WODate),
        render: (text) => <p>{dayjs(text).format('YYYY-MM-DD')}</p>
    },
    {
        title: 'Request By',
        dataIndex: ['WOTransaction', 'RequestBy'],
        sorter: (a, b) => a.WOTransaction.RequestBy.localeCompare(b.WOTransaction.RequestBy),
    },
    {
        title: 'Location Name',
        dataIndex: 'LocationName',
        sorter: (a, b) => a.LocationName.localeCompare(b.LocationName),
        render: (_, record) => {
            if (!record.LocationName) {
                return "-";
            }
            return record.LocationName;
        }
    },
    {
        title: 'WO Type',
        dataIndex: ['WOTransaction', 'WOType'],
        sorter: (a, b) => a.WOTransaction.WOType.localeCompare(b.WOTransaction.WOType),
    },
    {
        title: 'WO Request Number',
        dataIndex: ['WOTransaction', 'WORNumber'],
        sorter: (a, b) => a.WOTransaction.WORNumber.localeCompare(b.WOTransaction.WORNumber),
    },
    {
        title: 'Work Category',
        dataIndex: 'WorkCategory',
        sorter: (a, b) => a.WorkCategory.localeCompare(b.WorkCategory),
    },
    {
        title: 'Work SubCategory',
        dataIndex: 'WorkSubCategory',
        sorter: (a, b) => a.WorkSubCategory.localeCompare(b.WorkSubCategory),
    },
    {
        title: 'DueDateWO',
        dataIndex: ['WOTransaction', 'DueDateWO'],
        sorter: (a, b) => a.WOTransaction.DueDateWO.localeCompare(b.WOTransaction.DueDateWO),
        render: (text) => <p>{dayjs(text).format('YYYY-MM-DD')}</p>
    }
]