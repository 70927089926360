import React, { useState, useEffect, useCallback } from "react";
import Header from "../../../components/Header";
import { Box, Tooltip, Typography } from "@mui/material";
import EditPart from "./edit";
import DeletePart from "./delete";
import { Table } from "antd";
import SearchData from "../../global/components/FeatureTable/SearchData";
import ExportData from "../../global/components/FeatureTable/exportData";
import { ButtonAddNew } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleGridHeader from "../../../components/Global/StyleContent/StyleGrid/StyleGridHeader";
import AddAuthorityIdx from "../../../components/Global/Authority/AddAuthority";
import EditAuthorityIdx from "../../../components/Global/Authority/EditAuthority";
import DeleteAuthorityIdx from "../../../components/Global/Authority/DeleteAuthority";
import PrintAuthorityIdx from "../../../components/Global/Authority/PrintAuthority";
import DetailGrid from "../../../components/Global/Grid/DetailGrid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { getPart } from "../../../Api/Master/GetData";

const Part = () => {

    const myAddAuthIdx = AddAuthorityIdx();
    const myEditAuthIdx = EditAuthorityIdx();
    const myDeleteAuthIdx = DeleteAuthorityIdx();
    const myPrintAuthIdx = PrintAuthorityIdx();

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState("");

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await getPart();
            setData(response);
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setData([]);
            }
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const columns = [
        {
            key: 'key',
            title: 'No',
            dataIndex: 'key',
            width: 80,
            fixed: 'left',
            sorter: (a, b) => a.key - b.key,
        },
        {
            key: 'PartCode',
            title: 'Part Code',
            dataIndex: ['Part', 'PartCode'],
            fixed: 'left',
            sorter: (a, b) => a.Part.PartCode.localeCompare(b.Part.PartCode),
        },
        {
            key: 'PartName',
            title: 'Part Name',
            dataIndex: ['Part', 'PartName'],
            sorter: (a, b) => a.Part.PartName.localeCompare(b.Part.PartName),
        },
        {
            key: 'PartCategory',
            title: 'Part Category',
            dataIndex: 'CategoryName',
            sorter: (a, b) => a.CategoryName.localeCompare(b.CategoryName),
        },
        {
            key: 'MinStock',
            title: 'Minum Stock',
            dataIndex: ['Part', 'MinStock'],
            sorter: (a, b) => a.Part.MinStock.localeCompare(b.Part.MinStock),
        },
        {
            key: 'UnitName',
            title: 'Unit Name',
            dataIndex: 'UnitName',
            sorter: (a, b) => a.UnitName.localeCompare(b.UnitName),
        },
        {
            key: 'IsSuspend',
            title: 'Active',
            dataIndex: ['Part', 'IsSuspend'],
            width: 120,
            render: (isSuspend) => (
                <Box display="flex" justifyContent="center" >
                    {
                        isSuspend ? (
                            <Tooltip title="Inactive">
                                <CancelIcon color="error" />
                            </Tooltip>
                        ) : (
                            <Tooltip title="Active" >
                                <CheckCircleIcon color="success" />
                            </Tooltip >
                        )}
                </Box>
            )
        },
    ];

    if (myEditAuthIdx || myDeleteAuthIdx) {
        columns.push({
            title: 'Action',
            key: 'Action',
            fixed: 'right',
            width: 120,
            render: (_, record) => (
                <Box display="flex" justifyContent="center">
                    {myEditAuthIdx && (
                        <EditPart data={record} onEdit={fetchData} />
                    )}

                    {myDeleteAuthIdx && !record.Part.IsSuspend && (
                        <DeletePart data={record} onDelete={fetchData} />
                    )}
                </Box>
            ),
        });
    }

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredData = data.filter((item) =>
        Object.values(item).some(
            (val) => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
        )
    );

    const [selectedRows, setSelectedRows] = useState([]);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
            // console.log(selectedRows);
        },
    };

    const columnsExport = columns
        .filter((item) => item.key !== "Action" && item.key !== "key")
        .map((item) => {

            if (item.title === "Active") {
                return {
                    title: item.title,
                    key: item.dataIndex,
                    render: (record) => (record.Part.IsSuspend ? "Inactive" : "Active"),
                };
            }

            return {
                title: item.title,
                key: item.dataIndex,
            };
        });

    const processExportData = (data) =>
        data.map((item) => ({
            ...item,
            Part: {
                ...item.Part,
                IsSuspend: item.Part.IsSuspend ? "Inactive" : "Active",
            }
        }));

    return (
        <StyleContent>
            <StyleGridHeader>
                <Header title="PART" subtitle="All Part data" />
                <Box display="flex" justifyContent="end" gap="8px">
                    {myPrintAuthIdx && (
                        <ExportData
                            data={processExportData(selectedRows.length > 0 ? selectedRows : data)}
                            columns={columnsExport}
                            name="Part"
                        />
                    )}

                    {myAddAuthIdx &&
                        <ButtonAddNew
                            link="form"
                        />
                    }
                </Box>
            </StyleGridHeader>

            <SearchData
                filterValue={searchText}
                handleFilterChange={handleSearch}
            />

            <Table
                loading={isLoading}
                columns={columns}
                dataSource={filteredData}
                rowSelection={data.length === 0 ? null : rowSelection}
                expandable={
                    data.length === 0
                        ? null
                        : {
                            expandedRowRender: (record) => (
                                <DetailGrid>
                                    <Typography variant="h5" fontWeight={600}>
                                        Description :
                                    </Typography>
                                    <Typography>{record.Part.Description}</Typography>
                                </DetailGrid>
                            ),
                            rowExpandable: (record) => record.Part.Description !== "",
                        }
                }
                pagination={{
                    showSizeChanger: true,
                    defaultPageSize: 10,
                }}
                scroll={{ x: 1200 }}
            />
        </StyleContent>
    );
};

export default Part;
