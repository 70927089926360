import React, { useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import { getSukuCabang } from '../API';

const SukuCabang = () => {
    const [dataSource, setDataSource] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getSukuCabang();
                setDataSource(res);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);


    const data = dataSource.length > 0 ? [
        ["PartName", "CurrentStock", { role: "style" }],
        ...dataSource.map(item => [
            item.PartName,
            item.CurrentStock,
            item.CurrentStock > item.MinStock
                ? "#6fcf97"
                : item.CurrentStock === item.MinStock
                    ? "#f2c94c"
                    : "#eb5757"
        ])
    ] : [
        ["PartName", "CurrentStock", { role: "style" }],
        ["Part", 0, 15 > 8 ? "#6fcf97" : 15 === 8 ? "#f2c94c" : "#eb5757"],
    ];

    // const data = [
    //     ["Type", "Value", { role: "style" }], 
    //     ["Gear Box", 10, 10 > 10 ? "#6fcf97" : 10 === 10 ? "#f2c94c" : "#eb5757"],
    //     ["Control Panel", 8, 8 > 10 ? "#6fcf97" : 8 === 10 ? "#f2c94c" : "#eb5757"],
    //     ["Hydraulic Pump", 6, 6 > 0.6 ? "#6fcf97" : 6 === 0.6 ? "#f2c94c" : "#eb5757"],
    // ]


    const options = {
        legend: "none",
    };

    return (
        <Chart
            chartType="ColumnChart"
            width="100%"
            height="300px"
            data={data}
            options={options}
        />
    );
};

export default SukuCabang;
