import React, { useCallback, useEffect, useState } from 'react'
import StyleContent from '../../components/Global/StyleContent'
import StyleGridHeader from '../../components/Global/StyleContent/StyleGrid/StyleGridHeader'
import Header from '../../components/Header'
import SearchData from '../global/components/FeatureTable/SearchData'
import { Table } from 'antd'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import { getPMSchedule } from '../../Api/Report/GetData'

const Summary = () => {

  const { status } = useParams();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getPMSchedule();
      setData(response);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setData([]);
      }
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some(
      (val) => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  const columns = [
    {
      title: 'No',
      dataIndex: 'key',
      width: 100,
      fixed: 'left',
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: 'Schedule Date',
      dataIndex: 'ScheduleDate',
      sorter: (a, b) => a.ScheduleDate.localeCompare(b.ScheduleDate),
      render: (date) => <p>{dayjs(date).format('YYYY-MM-DD')}</p>
    },
    {
      title: 'Reference Name',
      dataIndex: 'RefName',
      sorter: (a, b) => a.RefName.localeCompare(b.RefName),
    },
    {
      title: 'PM Number',
      dataIndex: 'PMNumber',
      sorter: (a, b) => a.PMNumber.localeCompare(b.PMNumber),
    },
    {
      title: 'PM Date',
      dataIndex: 'PMDate',
      sorter: (a, b) => a.PMDate.localeCompare(b.PMDate),
    },
    {
      title: 'PM Name',
      dataIndex: 'PMName',
      sorter: (a, b) => a.PMName.localeCompare(b.PMName),
    },
    {
      title: 'Request By',
      dataIndex: 'RequestBy',
      sorter: (a, b) => a.RequestBy.localeCompare(b.RequestBy),
    },
    {
      title: 'PM Type',
      dataIndex: 'PMType',
      sorter: (a, b) => a.PMType.localeCompare(b.PMType),
    },
    {
      title: 'Schedule Type',
      dataIndex: 'ScheduleType',
      sorter: (a, b) => a.ScheduleType.localeCompare(b.ScheduleType),
    },
    {
      title: 'Schedule Start',
      dataIndex: 'ScheduleStart',
      sorter: (a, b) => a.ScheduleStart.localeCompare(b.ScheduleStart),
      render: (date) => <p>{dayjs(date).format('YYYY-MM-DD')}</p>
    },
    {
      title: 'Schedule End',
      dataIndex: 'ScheduleEnd',
      sorter: (a, b) => a.ScheduleEnd.localeCompare(b.ScheduleEnd),
      render: (date) => <p>{dayjs(date).format('YYYY-MM-DD')}</p>

    },
  ];

  return (
    <StyleContent>
      <StyleGridHeader>
        <Header title={`${status} Maintenance`} subtitle={`All data a Status ${status}`} />
      </StyleGridHeader>

      <SearchData
        filterValue={searchText}
        handleFilterChange={handleSearch}
      />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={filteredData}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        scroll={{ x: 2500 }}
      />
    </StyleContent>
  )
}

export default Summary