import React, { useCallback, useEffect, useState, useRef } from "react";
import { Autocomplete, Box, TextField, Button } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import Authentication from "../../auth";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ButtonForm } from "../../global/components/ButtonAction";
import dayjs from "dayjs";
import { getWORequest } from "../Api/GetData";
import FormTranApproval from "../TranApproval/form";
import StyleContent from "../../../components/Global/StyleContent";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { StyleButtonForm } from "../../global/StyleCSS";
import { getEquipment, getFacility, getLocation, getVehicle } from "../../../Api/Master/GetData";
import { postWORequest } from "../Api/PostData";
import { columnsEquipment, columnsFacility, columnsLocation, columnsRequestBy, columnsVehicle } from "./ColumnsWOReq";
import InputModal from "../../../components/Global/InputModal";
import { getEmployee } from "../../../Api/Employee/GetData";
import { useSelector } from "react-redux";
import TranView from "../TranApproval/tranView";


const FormWORequest = () => {

    Authentication();

    const { user } = useSelector((state) => state.auth);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const action = searchParams.get('action');
    const faccode = searchParams.get('faccode');
    const trannumber = searchParams.get('trannumber');

    const [WORequest, setWORequest] = useState([]);
    const [dataWOReq, setdataWOReq] = useState('');
    const [isDisable, setIsDisable] = useState(false);

    useEffect(() => {
        if (faccode && trannumber) {
            const fetchWORequest = async () => {
                try {
                    const response = await getWORequest(faccode, trannumber);
                    setWORequest(response[0].WORequest);
                    setdataWOReq(response[0]);
                } catch (error) {
                    console.log(error);
                }
            }

            fetchWORequest();

        }
    }, [faccode, trannumber]);

    useEffect(() => {
        if (faccode && trannumber) {
            if (WORequest) {
                setIsDisable(true);
                setSelectedWOType(WORequest.WOType);
                setWORNumber(WORequest.WORNumber);
                setWORDate(dayjs(WORequest.WORDate));
            }
        }
    }, [faccode, trannumber, WORequest]);

    const formRef = useRef(null);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [WORNumber, setWORNumber] = useState("");

    const [dataFacility, setDataFacility] = useState([]);
    const [selectFacility, setSelectFacility] = useState("");
    const [openModalFacility, setOpenModalFacility] = useState("");
    const FacilityCode = selectFacility ? selectFacility.FacilityCode : '';
    const FacilityName = selectFacility ? selectFacility.FacilityName : '';

    const [dataRequestBy, setDataRequestBy] = useState([]);
    const [selectRequestBy, setSelectRequestBy] = useState("");
    const [openModalRequestBy, setOpenModalRequestBy] = useState("");
    const RequestByCode = selectRequestBy ? selectRequestBy.Employee.EmpCode : '';
    const RequestByName = selectRequestBy ? selectRequestBy.Employee.EmpName : '';

    const [selectedWOType, setSelectedWOType] = useState("");

    const [dataWOTypeRef, setDataWOTypeRef] = useState([]);
    const [selectWOTypeRef, setSelectWOTypeRef] = useState("");
    const [openModalWOTypeRef, setOpenModalWOTypeRef] = useState("");
    const [WOTypeRefCode, setWOTypeRefCode] = useState("");
    const [WOTypeRefName, setWOTypeRefName] = useState("");
    const [LocationCode, setLocationCode] = useState("-");

    const [WORDate, setWORDate] = useState(dayjs());


    // FACILITY
    useEffect(() => {
        const fetchFacility = async () => {
            try {
                const response = await getFacility();
                const filterRes = response.filter((item) => item.IsSuspend !== true);
                setDataFacility(filterRes);
            } catch (error) {
                console.log(error);
            }
        }
        if (openModalFacility) {
            fetchFacility();
            setOpenModalFacility(false)
            setIsLoading(false);
        }
    }, [openModalFacility]);


    // EMPLOYEE || REQUEST BY
    useEffect(() => {
        const fetchEmployee = async () => {
            try {
                const res = await getEmployee();
                const filterRes = res.filter((item) => item.Employee.IsSuspend !== true);
                setDataRequestBy(filterRes);
            } catch (error) {
                console.log(error);
            }
        }

        if (openModalRequestBy) {
            fetchEmployee();
            setOpenModalRequestBy(false);
            setIsLoading(false);
        }

    }, [openModalRequestBy]);


    // WO TYPE REFERENCE
    useEffect(() => {
        const fetchWOTypeRef = async () => {
            try {
                switch (selectedWOType) {
                    case 'Location':
                        const resLoc = await getLocation(FacilityCode);
                        const filterResLoc = resLoc.filter((item) => item.Location.IsSuspend !== true);
                        setDataWOTypeRef(filterResLoc);
                        break;
                    case 'Equipment':
                        const resEquip = await getEquipment(FacilityCode);
                        const filterResEquip = resEquip.filter((item) => item.Equipment.IsSuspend !== true);
                        setDataWOTypeRef(filterResEquip);
                        break;
                    case 'Vehicle':
                        const resVehicle = await getVehicle();
                        const filterResVehicle = resVehicle.filter((item) => item.IsSuspend !== true);
                        setDataWOTypeRef(filterResVehicle);
                        break;
                    default:
                        // Handle default case if necessary
                        break;
                }
            } catch (error) {
                console.log(error);
            }
        }

        if (openModalWOTypeRef && FacilityCode) {
            fetchWOTypeRef();
            setOpenModalWOTypeRef(false);
            setIsLoading(false);
        }

    }, [FacilityCode, openModalWOTypeRef, selectedWOType]);


    useEffect(() => {
        if (selectWOTypeRef) {
            switch (selectedWOType) {
                case 'Location':
                    setWOTypeRefCode(selectWOTypeRef.Location.LocationCode);
                    setWOTypeRefName(selectWOTypeRef.Location.LocationName);
                    setLocationCode(selectWOTypeRef.Location.LocationCode);
                    break;
                case 'Equipment':
                    setWOTypeRefCode(selectWOTypeRef.Equipment.EquipmentCode);
                    setWOTypeRefName(selectWOTypeRef.Equipment.EquipmentName);
                    setLocationCode(selectWOTypeRef.Equipment.LocationCode);
                    break;
                case 'Vehicle':
                    setWOTypeRefCode(selectWOTypeRef.VehicleCode);
                    setWOTypeRefName(selectWOTypeRef.VehicleName);
                    break;
                default:
                    // Handle default case if necessary
                    break;
            }
        }
    }, [selectWOTypeRef, selectedWOType]);


    const FetchWORNumber = useCallback(async () => {
        try {
            const response = await getWORequest(FacilityCode);
            if (response.length > 0) {
                const WORData = response.filter(item => item.WORequest.WORNumber && item.WORequest.WORNumber.startsWith('WOR'));
                if (WORData.length > 0) {
                    const lastCode = WORData[WORData.length - 1].WORequest.WORNumber;
                    const nextNumber = parseInt(lastCode.substr(3)) + 1;
                    setWORNumber(`WOR${nextNumber.toString().padStart(4, "0")}`);
                } else {
                    setWORNumber("WOR0001");
                }
            } else {
                setWORNumber("WOR0001");
            }
        } catch (error) {
            setWORNumber("WOR0001");
            console.log(error);
        }
    }, [FacilityCode]);

    useEffect(() => {
        if (FacilityCode) {
            FetchWORNumber();
        }
    }, [FacilityCode, FetchWORNumber]);

    useEffect(() => {
        if (formRef.current) {
            if (faccode && trannumber) {
                if (WORequest && dataWOReq) {
                    formRef.current.setFieldValue("FacilityName", dataWOReq.FacilityName);
                    formRef.current.setFieldValue("WOTypeRefName", dataWOReq.WOTypeRefName);
                    formRef.current.setFieldValue("RequestBy", dataWOReq.EmployeeName);
                }
            } else {
                formRef.current.setFieldValue("FacilityName", FacilityName);
                formRef.current.setFieldValue("WOTypeRefName", WOTypeRefName);
                formRef.current.setFieldValue("RequestBy", RequestByName);
                formRef.current.setFieldValue("WORNumber", WORNumber);

            }
        }
    }, [FacilityName, RequestByName, WORNumber, WORequest, WOTypeRefName, dataWOReq, faccode, trannumber]);


    const handleWOTypeSelection = (event, value) => {
        if (value) {
            setSelectedWOType(value.WOTypeName);
            setWOTypeRefCode([]);
            setSelectWOTypeRef("");
            setOpenModalWOTypeRef("");
            setWOTypeRefCode("");
            setWOTypeRefName("");
            setLocationCode("");
        } else {
            setSelectedWOType(null)
        }
    };

    const handleCancel = () => {
        formRef.current.resetForm();
        setWORNumber('');
        setWORDate(dayjs());
        setSelectedWOType(null);
    };

    const handleFormSubmit = async (values) => {
        setLoading(true);
        try {
            const WORDateNow = WORDate.format('YYYY-MM-DD');
            const payload = {
                AccountId: user && user.AccountId,
                FacilityCode: FacilityCode,
                WORNumber: WORNumber,
                WORDate: WORDateNow,
                RequestBy: RequestByCode,
                LocationCode: LocationCode,
                Priority: 0,
                WOType: selectedWOType,
                WOTypeRefCode: WOTypeRefCode,
                IsAutoNo: false,
                IsClosed: false,
                AppStatus: "",
                TranStatus: 0,
                Description: values.Description
            };

            console.log(payload);

            const response = await postWORequest(payload);
            enqueueSnackbar(response.data.statusMessage, { variant: "success" });
            navigate("/transaction/worequest");

        } catch (error) {
            if (error.response && error.response.data && error.response.data.statusMessage) {
                const errorMessage = error.response.data.statusMessage;
                enqueueSnackbar(errorMessage, { variant: "error" });
            } else {
                enqueueSnackbar("Data failed to save.", { variant: "error" });
            }
            console.error("error code", error);
        }
        setLoading(false);
    };

    return (
        <>

            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
                innerRef={formRef}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                }) => (
                    <Box>
                        <StyleContent>

                            <Header title="WO REQUEST" subtitle="Create a WO Request" />

                            <StyleForm>

                                <InputModal
                                    title="FACILITY"
                                    label="Facility Name"
                                    name="FacilityName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.FacilityName}
                                    error={!!touched.FacilityName && !!errors.FacilityName}
                                    helperText={touched.FacilityName && errors.FacilityName}
                                    dataSource={dataFacility}
                                    columns={columnsFacility}
                                    loading={isLoading}
                                    propsSelecteRow={(values) => setSelectFacility(values)}
                                    propsOpenModal={(values) => setOpenModalFacility(values)}
                                    LinktoCreate={`/master/facility/form`}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="WO Request Number*"
                                    onBlur={handleBlur}
                                    value={WORNumber}
                                    onChange={(e) => {
                                        handleChange(e)
                                        setWORNumber(e.target.value)
                                    }}
                                    name="WORNumber"
                                    error={!!touched.WORNumber && !!errors.WORNumber}
                                    helperText={touched.WORNumber && errors.WORNumber}
                                    sx={{ gridColumn: "span 2" }}
                                    inputProps={{ maxLength: 10 }}
                                    InputProps={{
                                        readOnly: isDisable,
                                    }}
                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="WO Request Date*"
                                        value={WORDate}
                                        onBlur={handleBlur}
                                        onChange={(date) => setWORDate(date)}
                                        name="WORDate"
                                        error={!!touched.WORDate && !!errors.WORDate}
                                        helperText={touched.WORDate && errors.WORDate}
                                        sx={{ gridColumn: "span 2" }}
                                        readOnly={isDisable}
                                    />
                                </LocalizationProvider>

                                <InputModal
                                    title="EMPLOYEE"
                                    label="Request By"
                                    name="RequestBy"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.RequestBy}
                                    error={!!touched.RequestBy && !!errors.RequestBy}
                                    helperText={touched.RequestBy && errors.RequestBy}
                                    dataSource={dataRequestBy}
                                    columns={columnsRequestBy}
                                    loading={isLoading}
                                    propsSelecteRow={(values) => setSelectRequestBy(values)}
                                    propsOpenModal={(values) => setOpenModalRequestBy(values)}
                                    LinktoCreate={`/employee/employee/form`}
                                />

                                <Autocomplete
                                    fullWidth
                                    disablePortal
                                    options={WOTypeData}
                                    getOptionLabel={(option) => option.WOTypeName}
                                    sx={{ gridColumn: "span 2" }}
                                    onChange={(event, value) => {
                                        handleWOTypeSelection(event, value);
                                        setFieldValue("WOType", value ? value.WOTypeName : "");
                                    }}
                                    value={
                                        WOTypeData.find((option) => option.WOTypeName === selectedWOType) || null
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="WO Type*"
                                            onBlur={handleBlur}
                                            error={!!touched.WOType && !!errors.WOType}
                                            helperText={touched.WOType && errors.WOType}
                                        />
                                    )}
                                    readOnly={isDisable}
                                />

                                <InputModal
                                    title="WO TYPE REFERENCE"
                                    label="WO Type Reference"
                                    name="WOTypeRefName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.WOTypeRefName}
                                    error={!!touched.WOTypeRefName && !!errors.WOTypeRefName}
                                    helperText={touched.WOTypeRefName && errors.WOTypeRefName}
                                    dataSource={dataWOTypeRef}
                                    columns={
                                        (selectedWOType === "Location" && columnsLocation) ||
                                        (selectedWOType === "Equipment" && columnsEquipment) ||
                                        (selectedWOType === "Vehicle" && columnsVehicle)
                                    }
                                    loading={isLoading}
                                    propsSelecteRow={(values) => setSelectWOTypeRef(values)}
                                    propsOpenModal={(values) => setOpenModalWOTypeRef(values)}
                                    LinktoCreate={
                                        (selectedWOType === "Location" && `/master/location/form?faccode=${FacilityCode}`) ||
                                        (selectedWOType === "Equipment" && `/master/equipment/form?faccode=${FacilityCode}`) ||
                                        (selectedWOType === "Vehicle" && "/master/vehicle/form")
                                    }
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Description"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={WORequest ? (WORequest.Description || values.Description) : values.Description}
                                    name="Description"
                                    sx={{ gridColumn: "span 4" }}
                                    multiline
                                    rows={4}
                                    InputProps={{
                                        readOnly: isDisable,
                                    }}
                                />

                            </StyleForm>
                        </StyleContent>

                        {
                            (faccode && trannumber) ? (
                                action === 'process' ? (
                                    <FormTranApproval tranIdx={1402} dataSource={WORequest} />
                                ) : (
                                    <TranView />
                                )
                            ) : (
                                <ButtonForm
                                    handleCancel={handleCancel}
                                    handleSubmit={handleSubmit}
                                    loading={loading}
                                />
                            )
                        }


                    </Box>
                )}
            </Formik>
        </>
    );
};

const checkoutSchema = yup.object().shape({
    FacilityName: yup
        .string()
        .required("Please complete this Facility Code."),
    WORNumber: yup
        .string()
        .max(30, "Maximum of 30 characters allowed")
        .required("Please complete this WO Request Number."),
    RequestBy: yup
        .string()
        .required("Please complete this Request By."),
    WOType: yup
        .string()
        .max(30, "Maximum of 30 characters allowed")
        .required("Please complete this WO Type."),
    WOTypeRefName: yup
        .string()
        .max(30, "Maximum of 30 characters allowed")
        .required("Please complete this WO Type Ref Code."),
});

const initialValues = {
    FacilityName: "",
    WORNumber: "",
    WORDate: "",
    RequestBy: "",
    WOType: "",
    WOTypeRefName: "",
    Description: "",
};

const WOTypeData = [
    {
        WOTypeName: "Location"
    },
    {
        WOTypeName: "Equipment"
    }
    ,
    {
        WOTypeName: "Vehicle"
    }
]


export default FormWORequest;
