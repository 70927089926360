import React, { useEffect, useRef, useState } from "react";
import { Box, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { ButtonForm } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { getEquipmentVehicle, getIcon, getSensor, getType } from "../../../Api/Master/GetData";
import { postSensor } from "../../../Api/Master/PostData";
import InputModal from "../../../components/Global/InputModal";
import { columnsAssetObject, columnsSensorType, columnsWarningIcon } from "./ColumnsSensor";

const FormSensor = () => {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [SensorCode, setSensorCode] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const [dataSensorType, setDataSensorType] = useState([]);
  const [selectSensorType, setSelectSensorType] = useState("");
  const [openModalSensorType, setOpenModalSensorType] = useState("");
  const SensorTypeCode = selectSensorType ? selectSensorType.TypeCode : '';
  const SensorTypeName = selectSensorType ? selectSensorType.TypeName : '';

  const [dataWarningIcon, setDataWarningIcon] = useState([]);
  const [selectWarningIcon, setSelectWarningIcon] = useState("");
  const [openModalWarningIcon, setOpenModalWarningIcon] = useState("");
  const WarningIconId = selectWarningIcon ? selectWarningIcon.IconId : '';
  const WarningIconName = selectWarningIcon ? selectWarningIcon.IconName : '';

  const [dataAssetObject, setDataAssetObject] = useState([]);
  const [selectAssetObject, setSelectAssetObject] = useState("");
  const [openModalAssetObject, setOpenModalAssetObject] = useState("");
  const AssetObjectCode = selectAssetObject ? selectAssetObject.SensorCode : '';
  const AssetObjectName = selectAssetObject ? selectAssetObject.SensorName : '';



  // TYPE
  useEffect(() => {
    const fetchDataType = async () => {
      try {
        const response = await getType("Sensor");
        const filterRes = response.filter((item) => item.IsSuspend !== true);
        setDataSensorType(filterRes);
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };
    if (openModalSensorType) {
      fetchDataType();
      setOpenModalSensorType(false);
    }
  }, [openModalSensorType]);


  // ICON
  useEffect(() => {
    const fetchIcon = async () => {
      try {
        const res = await getIcon();
        setDataWarningIcon(res);
      } catch (error) {
        console.log(error);
      }
    }

    if (openModalWarningIcon) {
      fetchIcon();
      setOpenModalWarningIcon(false);
      setIsLoading(false);
    }

  }, [openModalWarningIcon]);


  // ASSET OBJECT
  useEffect(() => {
    const fetchAssetObject = async () => {
      try {
        const res = await getEquipmentVehicle();
        setDataAssetObject(res);
      } catch (error) {
        console.log(error);
      }
    }

    if (openModalAssetObject) {
      fetchAssetObject();
      setOpenModalAssetObject(false);
      setIsLoading(false);
    }

  }, [openModalAssetObject]);



  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("SensorType", SensorTypeName);
      formRef.current.setFieldValue("WarningIcon", WarningIconName);
      formRef.current.setFieldValue("AssetObject", AssetObjectName);
    }
  }, [AssetObjectName, SensorTypeName, WarningIconName])


  const FetchSensorCode = async () => {
    try {
      const response = await getSensor();
      if (response.length > 0) {
        const SRData = response.filter(
          (item) => item.SensorCode && item.SensorCode.startsWith("SR")
        );
        if (SRData.length > 0) {
          const lastCode = SRData[SRData.length - 1].SensorCode;
          const nextNumber = parseInt(lastCode.substr(2)) + 1;
          setSensorCode(`SR${nextNumber.toString().padStart(4, "0")}`);
        } else {
          setSensorCode("SR0001");
        }
      } else {
        setSensorCode("SR0001");
      }
    } catch (error) {
      setSensorCode("SR0001");
      console.log(error);
    }
  };

  useEffect(() => {
    FetchSensorCode();
  }, []);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("SensorCode", SensorCode);
    }
  }, [SensorCode]);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const modifiedValues = {
        ...values,
        SensorCode: SensorCode,
        SensorTypeCode: SensorTypeCode,
        WarningIconId: WarningIconId,
        ObjectCode: AssetObjectCode,
      };
      const response = await postSensor(modifiedValues);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      navigate("/master/Sensor");
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to save.", { variant: "error" });
      }
      console.error("error code", error.response);
    }
    setLoading(false);
  };

  return (
    <>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={{ ...initialValues, SensorCode: SensorCode }}
        validationSchema={checkoutSchema}
        innerRef={formRef}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Box>
            <StyleContent>
              <Header title="SENSOR" subtitle="Create a data Sensor" />
              <StyleForm>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Sensor Code*"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setSensorCode(e.target.value);
                  }}
                  value={SensorCode}
                  name="SensorCode"
                  error={!!touched.SensorCode && !!errors.SensorCode}
                  helperText={touched.SensorCode && errors.SensorCode}
                  sx={{ gridColumn: "span 2" }}
                  inputProps={{ maxLength: 6 }}
                />

                <TextField
                  autoFocus
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Sensor Name*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.SensorName}
                  name="SensorName"
                  error={!!touched.SensorName && !!errors.SensorName}
                  helperText={touched.SensorName && errors.SensorName}
                  sx={{ gridColumn: "span 2" }}
                />

                <InputModal
                  title="TYPE"
                  label="Sensor Type"
                  name="SensorType"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.SensorType}
                  error={!!touched.SensorType && !!errors.SensorType}
                  helperText={touched.SensorType && errors.SensorType}
                  dataSource={dataSensorType}
                  columns={columnsSensorType}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectSensorType(values)}
                  propsOpenModal={(values) => setOpenModalSensorType(values)}
                  LinktoCreate={`/master/type/form`}
                />

                <InputModal
                  title="ICON"
                  label="Warning Icon"
                  name="WarningIcon"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.WarningIcon}
                  error={!!touched.WarningIcon && !!errors.WarningIcon}
                  helperText={touched.WarningIcon && errors.WarningIcon}
                  dataSource={dataWarningIcon}
                  columns={columnsWarningIcon}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectWarningIcon(values)}
                  propsOpenModal={(values) => setOpenModalWarningIcon(values)}
                  LinktoCreate={`/master/icon/form`}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Lower Limit*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.LLimit}
                  name="LLimit"
                  error={!!touched.LLimit && !!errors.LLimit}
                  helperText={touched.LLimit && errors.LLimit}
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Upper Limit*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.ULimit}
                  name="ULimit"
                  error={!!touched.ULimit && !!errors.ULimit}
                  helperText={touched.ULimit && errors.ULimit}
                  sx={{ gridColumn: "span 2" }}
                />

                <InputModal
                  title="ASSET"
                  label="Asset Object"
                  name="AssetObject"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.AssetObject}
                  error={!!touched.AssetObject && !!errors.AssetObject}
                  helperText={touched.AssetObject && errors.AssetObject}
                  dataSource={dataAssetObject}
                  columns={columnsAssetObject}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectAssetObject(values)}
                  propsOpenModal={(values) => setOpenModalAssetObject(values)}
                // LinktoCreate={`/master/icon/form`}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Description}
                  name="Description"
                  sx={{ gridColumn: "span 2" }}
                  multiline
                  rows={4}
                />
              </StyleForm>
            </StyleContent>

            <ButtonForm
              handleSubmit={handleSubmit}
              loading={loading}
            />
          </Box>
        )}
      </Formik>
    </>
  );
};

const checkoutSchema = yup.object().shape({
  SensorCode: yup
    .string()
    .max(20, "Maximum of 20 characters allowed")
    .required("Please complete this Sensor Code."),
  SensorName: yup
    .string()
    .max(20, "Maximum of 20 characters allowed")
    .required("Please complete this Sensor Name."),
  SensorType: yup
    .string()
    .required("Please complete this Sensor Type."),
  WarningIcon: yup
    .string()
    .required("Please complete this Icon."),
  LLimit: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this LLimit."),
  ULimit: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this ULimit."),
  AssetObject: yup
    .string()
    .required("Please complete this Object."),
});
const initialValues = {
  SensorCode: "",
  SensorName: "",
  SensorType: "",
  WarningIcon: "",
  LLimit: "",
  ULimit: "",
  AssetObject: "",
  Description: "",
};

export default FormSensor;
