import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ButtonForm } from "../../global/components/ButtonAction";
import { onlyNumber } from "../../global/Components";
import StyleContent from "../../../components/Global/StyleContent";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { getFacility, getType, getVehicle } from "../../../Api/Master/GetData";
import { postVehicle } from "../../../Api/Master/PostData";
import InputModal from "../../../components/Global/InputModal";
import { getEmployee } from "../../../Api/Employee/GetData";
import { columnsFacility, columnsType, columnsVehicleOwner } from "./ColumnsVehicle";

const FormVehicle = () => {

  const formRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(true);

  const [loading, setLoading] = useState(false);

  const [vehicleCode, setVehicleCode] = useState("");

  const [purchaseDate, setPurchaseDate] = useState(null);
  const [warrantyExpDate, setWarrantyExpDate] = useState(null);

  const [dataFacility, setDataFacility] = useState([]);
  const [selectFacility, setSelectFacility] = useState("");
  const [openModalFacility, setOpenModalFacility] = useState("");
  const FacilityCode = selectFacility ? selectFacility.FacilityCode : '';
  const FacilityName = selectFacility ? selectFacility.FacilityName : '';


  const [dataType, setDataType] = useState([]);
  const [selectType, setSelectType] = useState("");
  const [openModalType, setOpenModalType] = useState("");
  const TypeCode = selectType ? selectType.TypeCode : '';
  const TypeName = selectType ? selectType.TypeName : '';


  const [dataVehicleOwner, setDataVehicleOwner] = useState([]);
  const [selectVehicleOwner, setSelectVehicleOwner] = useState("");
  const [openModalVehicleOwner, setOpenModalVehicleOwner] = useState("");
  const VehicleOwnerCode = selectVehicleOwner ? selectVehicleOwner.Employee.EmpCode : '';
  const VehicleOwnerName = selectVehicleOwner ? selectVehicleOwner.Employee.EmpName : '';

  // FACILITY
  useEffect(() => {
    const fetchFacility = async () => {
      try {
        const res = await getFacility();
        const filterRes = res.filter((item) => item.IsSuspend !== true);
        setDataFacility(filterRes);
      } catch (error) {
        console.log(error);
      }
    }

    if (openModalFacility) {
      setIsLoading(false);
      fetchFacility();
      setOpenModalFacility(false);
    }
  }, [openModalFacility]);


  // TYPE
  useEffect(() => {
    const fetchType = async () => {
      try {
        const res = await getType("Vehicle");
        const filterRes = res.filter((item) => item.IsSuspend !== true);
        setDataType(filterRes);
      } catch (error) {
        console.log(error);
      }
    }

    if (openModalType) {
      setIsLoading(false);
      fetchType();
      setOpenModalType(false);
    }
  }, [openModalType]);


  // EMPLOYEE
  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const res = await getEmployee();
        const filterRes = res.filter((item) => item.Employee.IsSuspend !== true);
        setDataVehicleOwner(filterRes);
      } catch (error) {
        console.log(error);
      }
    }

    if (openModalVehicleOwner) {
      fetchEmployee();
      setIsLoading(false);
      setOpenModalVehicleOwner(false);
    }
  }, [openModalVehicleOwner]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("FacilityName", FacilityName);
      formRef.current.setFieldValue("Type", TypeName);
      formRef.current.setFieldValue("VehicleOwner", VehicleOwnerName);
    }
  }, [FacilityName, TypeName, VehicleOwnerName]);

  const FetchVehicleCode = useCallback(async () => {
    try {
      const response = await getVehicle(FacilityCode);
      if (response.length > 0) {
        const VEData = response.filter(item => item.VehicleCode && item.VehicleCode.startsWith('VE'));
        if (VEData.length > 0) {
          const lastCode = VEData[VEData.length - 1].VehicleCode;
          const nextNumber = parseInt(lastCode.substr(2)) + 1;
          setVehicleCode(`VE${nextNumber.toString().padStart(4, "0")}`);
        } else {
          setVehicleCode("VE0001");
        }
      } else {
        setVehicleCode("VE0001");
      }
    } catch (error) {
      setVehicleCode("VE0001");
      console.log(error);
    }
  }, [FacilityCode]);

  useEffect(() => {
    if (FacilityCode) {
      FetchVehicleCode();
    }
  }, [FacilityCode, FetchVehicleCode]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("VehicleCode", vehicleCode);
    }
  }, [vehicleCode]);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {

      const purchaseDateNow = purchaseDate.format('YYYY-MM-DD');
      const warrantyExpDateNow = warrantyExpDate.format('YYYY-MM-DD');

      const payload = {
        ...values,
        FacilityCode: FacilityCode,
        VehicleCode: vehicleCode,
        PurchaseDate: purchaseDateNow,
        WarrantyExpDate: warrantyExpDateNow,
        VehicleOwner: VehicleOwnerCode,
        ModelTypeCode: TypeCode,
      };
      // console.log(payload);
      const response = await postVehicle(payload);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      navigate("/master/vehicle");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.statusMessage) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to save.", { variant: "error" });
      }
      console.error("error code", error.response);
    }
    setLoading(false);
  };

  const handleKeyPress = (e) => {
    if (!onlyNumber.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={checkoutSchema}
      innerRef={formRef}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <Box>
          <StyleContent>
            <Header title="VEHICLE" subtitle="Create a Vehicle" />

            <StyleForm>

              <InputModal
                title="FACILITY"
                label="Facility Name*"
                name="FacilityName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.FacilityName}
                error={!!touched.FacilityName && !!errors.FacilityName}
                helperText={touched.FacilityName && errors.FacilityName}
                dataSource={dataFacility}
                columns={columnsFacility}
                loading={isLoading}
                propsSelecteRow={(values) => setSelectFacility(values)}
                propsOpenModal={(values) => setOpenModalFacility(values)}
                LinktoCreate={`/master/facility/form`}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Vehicle Code*"
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e)
                  setVehicleCode(e.target.value)
                }}
                value={vehicleCode}
                name="VehicleCode"
                InputProps={{
                  maxLength: 6,
                }}
                error={!!touched.VehicleCode && !!errors.VehicleCode}
                helperText={touched.VehicleCode && errors.VehicleCode}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Vehicle Name*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.VehicleName}
                name="VehicleName"
                error={!!touched.VehicleName && !!errors.VehicleName}
                helperText={touched.VehicleName && errors.VehicleName}
                sx={{ gridColumn: "span 2" }}
              />

              <InputModal
                title="TYPE"
                label="Model Type*"
                name="Type"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Type}
                error={!!touched.Type && !!errors.Type}
                helperText={touched.Type && errors.Type}
                dataSource={dataType}
                columns={columnsType}
                loading={isLoading}
                propsSelecteRow={(values) => setSelectType(values)}
                propsOpenModal={(values) => setOpenModalType(values)}
                LinktoCreate={`/master/type/form?moduleid=Vehicle`}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="No Pol*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.NoPol}
                name="NoPol"
                error={!!touched.NoPol && !!errors.NoPol}
                helperText={touched.NoPol && errors.NoPol}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Asset No*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.AssetNo}
                name="AssetNo"
                error={!!touched.AssetNo && !!errors.AssetNo}
                helperText={touched.AssetNo && errors.AssetNo}
                sx={{ gridColumn: "span 2" }}
              />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Purchase Date*"
                  value={purchaseDate}
                  onBlur={handleBlur}
                  onChange={(date) => setPurchaseDate(date)}
                  name="PurchaseDate"
                  error={!!touched.PurchaseDate && !!errors.PurchaseDate}
                  helperText={touched.PurchaseDate && errors.PurchaseDate}
                  sx={{ gridColumn: "span 2" }}
                />
              </LocalizationProvider>


              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Warranty Exp Date*"
                  value={warrantyExpDate}
                  onBlur={handleBlur}
                  onChange={(date) => setWarrantyExpDate(date)}
                  name="WarrantyExpDate"
                  error={!!touched.WarrantyExpDate && !!errors.WarrantyExpDate}
                  helperText={touched.WarrantyExpDate && errors.WarrantyExpDate}
                  sx={{ gridColumn: "span 2" }}
                />
              </LocalizationProvider>

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Meter Reading*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.MeterReading}
                name="MeterReading"
                error={!!touched.MeterReading && !!errors.MeterReading}
                helperText={touched.MeterReading && errors.MeterReading}
                sx={{ gridColumn: "span 2" }}
                onKeyPress={handleKeyPress}
              />

              <InputModal
                title="EMPLOYEE"
                label="Vehicle Owner*"
                name="VehicleOwner"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.VehicleOwner}
                error={!!touched.VehicleOwner && !!errors.VehicleOwner}
                helperText={touched.VehicleOwner && errors.VehicleOwner}
                dataSource={dataVehicleOwner}
                columns={columnsVehicleOwner}
                loading={isLoading}
                propsSelecteRow={(values) => setSelectVehicleOwner(values)}
                propsOpenModal={(values) => setOpenModalVehicleOwner(values)}
                LinktoCreate={`/employee/employee/form`}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Description}
                name="Description"
                sx={{ gridColumn: "span 4" }}
                multiline
                rows={4}
              />

            </StyleForm>
          </StyleContent>

          <ButtonForm
            handleSubmit={handleSubmit}
            loading={loading}
          />

        </Box>
      )}
    </Formik>
  );
};

const checkoutSchema = yup.object().shape({
  FacilityName: yup.string().required("Please complete this Facility Name."),
  VehicleCode: yup
    .string()
    .max(20, "Maximum of 20 characters allowed")
    .required("Please complete this Vehicle Code"),
  VehicleName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Vehicle Name"),
  Type: yup
    .string()
    .required("Please complete this Model Type"),
  NoPol: yup
    .string()
    .max(20, "Maximum of 20 characters allowed")
    .required("Please complete this No Pol"),
  AssetNo: yup
    .string()
    .max(30, "Maximum of 30 characters allowed")
    .required("Please complete this Asset No"),
  MeterReading: yup
    .string()
    .required("Please complete this Meter Reading"),
  VehicleOwner: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Vehicle Owner"),
});
const initialValues = {
  FacilityName: "",
  VehicleCode: "",
  VehicleName: "",
  Type: "",
  NoPol: "",
  AssetNo: "",
  MeterReading: "",
  VehicleOwner: "",
  Description: ""
};

export default FormVehicle;
