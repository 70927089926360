import React, { useState } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import Header from "../../../components/Header";
import BlockIcon from '@mui/icons-material/Block';
import { useSnackbar } from "notistack";
import { ButtonDelete } from "../../global/components/ButtonAction";
import { Modal } from "antd";
import { deleteCategory } from "../../../Api/Master/DeleteData";

const DeleteCategory = ({ data, onDelete }) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await deleteCategory(data.ModuleID, data.CatCode);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      onDelete(data.ModuleID, data.CatCode);
      setOpen(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to delete.", { variant: "error" });
      }
      console.error("error code", error.response);
      setOpen(false);
    }
    setLoading(false);
  };

  return (
    <Box>

      <Tooltip title="Inactive" fontSize="large">
        <IconButton onClick={handleOpen}>
          <BlockIcon />
        </IconButton>
      </Tooltip>

      <Modal
        title={
          <Header
            title="CATEGORY"
            subtitle="Inactive Data a Category"
          />
        }
        centered
        open={open}
        closable={false}
        footer={() => (
          <ButtonDelete
            handleClose={handleClose}
            handleDelete={handleDelete}
            loading={loading}
          />
        )}
      >
        <Box>
          <p>
            Are you sure you want to Inactive data with this Category Code{data.CatCode} with the Category Name {data.CatName} ?
          </p>
        </Box>
      </Modal>
    </Box>
  );
};

export default DeleteCategory;
