import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react"; // Import FullCalendar component
import dayGridPlugin from "@fullcalendar/daygrid"; // Import DayGrid plugin
import StyleContent from "../../../components/Global/StyleContent";
import { Box } from "@mui/material";
import Header from "../../../components/Header";
import { getPMSchedule } from "../../../Api/Report/GetData";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const PMSchedule = () => {

  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await getPMSchedule();
      setData(response);
    } catch (error) {
      console.log(error.response.data);
    }
  };

  useEffect(() => {
    fetchData();
    // setIsLoading(false);
  }, []);

  const events = [
    // { title: "Event 1", date: "2024-12-12" },
    ...data.map((item) => ({
      ...item,
      title: item.PMName,
      date: dayjs(item.ScheduleDate).format('YYYY-MM-DD'),
    })
    )
    // { title: "Event 2", date: "2024-11-21" },
  ];

  const handleDetail = (values) => {
    // console.log(values);
    const data = values[0];    
    navigate(`/transaction/pmconfiguration/form?action=view&faccode=${data.FacilityCode}&trannumber=${data.PMNumber}`)
  }


  return (
    <>
      <StyleContent>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Header
            title="PM SCHEDULE"
            subtitle="All PM Schedule"
          />
        </Box>
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          events={events}
          // eventClick={(info) => alert(`Event: ${info.event.title}`)}
          eventClick={() => handleDetail(events)}
        />
      </StyleContent>
    </>
  );
};

export default PMSchedule;
