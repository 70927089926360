import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box, Typography } from "@mui/material";
import Authentication from "../../auth";
import { Space, Table, Tabs } from "antd";
import ExportData from "../../global/components/FeatureTable/exportData";
import { ButtonAddNew } from "../../global/components/ButtonAction";
import SearchData from "../../global/components/FeatureTable/SearchData";
import { getDMMainTrasaction } from "../Api/GetData";
import DataDMRE from "./DM_RE";
import DataDMPart from "./DM_Part";
import DataDMDoc from "./DM_Doc";
import DataDMCT from "./DM_CT";
import EditDMTransaction from "./edit";
import DeleteDMTransaction from "./delete";
import StyleContent from "../../../components/Global/StyleContent";
import StyleGridHeader from "../../../components/Global/StyleContent/StyleGrid/StyleGridHeader";
import AddAuthorityIdx from "../../../components/Global/Authority/AddAuthority";
import EditAuthorityIdx from "../../../components/Global/Authority/EditAuthority";
import DeleteAuthorityIdx from "../../../components/Global/Authority/DeleteAuthority";
import PrintAuthorityIdx from "../../../components/Global/Authority/PrintAuthority";
import dayjs from "dayjs";

const RecordDetail = ({ children }) => {
    return (
        <div
            style={{
                display: "flex",
                gap: "8px",
                marginBottom: "14px",
                borderBottom: '2px solid #00000015',
                padding: "10px 0"
            }}
        >
            {children}
            {/* <Typography variant="h5" fontWeight={600}>Description : </Typography>
        <Typography variant="h6">{record.DMTransaction.Description}</Typography> */}
        </div>
    )
}

const DMTransaction = () => {

    Authentication();

    const myAddAuthIdx = AddAuthorityIdx();
    const myEditAuthIdx = EditAuthorityIdx();
    const myDeleteAuthIdx = DeleteAuthorityIdx();
    const myPrintAuthIdx = PrintAuthorityIdx();

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState("");

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await getDMMainTrasaction();
            setData(response);
        } catch (error) {
            console.log(error.response.data);
        }
        setIsLoading(false)
    };

    useEffect(() => {
        fetchData();
    }, []);

    const expandedRowRender = (record) => {

        return (
            <Box sx={{ margin: "10px" }}>
                {record.DMTransaction.Description !== '' ?
                    <RecordDetail>
                        <Typography variant="h5" fontWeight={600}>Description : </Typography>
                        <Typography variant="h6">{record.DMTransaction.Description}</Typography>
                    </RecordDetail>
                    : ''
                }
                {record.DMTransaction.Comments !== '' ?
                    <RecordDetail>
                        <Typography variant="h5" fontWeight={600}>Comments : </Typography>
                        <Typography variant="h6">{record.DMTransaction.Comments}</Typography>
                    </RecordDetail>
                    : ''
                }
                <Tabs
                    // onChange={onChange}
                    type="card"
                    items={[
                        {
                            label: 'Resource',
                            key: 'resource',
                            children: <DataDMRE record={record.DMTransaction} />,
                        },
                        {
                            label: 'Part',
                            key: 'Part',
                            children: <DataDMPart record={record.DMTransaction} />,
                        },
                        {
                            label: 'Document',
                            key: 'document',
                            children: <DataDMDoc record={record.DMTransaction} />,
                        },
                        {
                            label: 'Contact',
                            key: 'contact',
                            children: <DataDMCT record={record.DMTransaction} />,
                        },
                    ]}
                />
            </Box>
        );
    };


    const columns = [
        {
            title: 'No',
            dataIndex: 'key',
            width: 100,
            fixed: 'left',
            sorter: (a, b) => a.key - b.key,
        },
        {
            title: 'Facility Name',
            dataIndex: 'FacilityName',
            sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
        },
        {
            title: 'DM Number',
            dataIndex: ['DMTransaction', 'DMNumber'],
            sorter: (a, b) => a.DMTransaction.DMNumber.localeCompare(b.DMTransaction.DMNumber),
        },
        {
            title: 'DM Date',
            dataIndex: ['DMTransaction', 'DMDate'],
            sorter: (a, b) => a.DMTransaction.DMDate.localeCompare(b.DMTransaction.DMDate),
            render: (value) => <p>{dayjs(value).format('YYYY-MM-DD')}</p>
        },
        {
            title: 'DM Status',
            dataIndex: ['DMTransaction', 'DMStatus'],
            sorter: (a, b) => a.DMTransaction.DMStatus.localeCompare(b.DMTransaction.DMStatus),
        },
        {
            title: 'DM Type',
            dataIndex: ['DMTransaction', 'DMType'],
            sorter: (a, b) => a.DMTransaction.DMType.localeCompare(b.DMTransaction.DMType),
        },
        {
            title: 'WO Number',
            dataIndex: ['DMTransaction', 'WONumber'],
            sorter: (a, b) => a.DMTransaction.WONumber.localeCompare(b.DMTransaction.WONumber),
        },
        {
            title: 'Due Date',
            dataIndex: ['DMTransaction', 'DueDate'],
            sorter: (a, b) => a.DMTransaction.DueDate.localeCompare(b.DMTransaction.DueDate),
            render: (value) => <p>{dayjs(value).format('YYYY-MM-DD')}</p>
        },
        {
            title: 'Reschedule Date',
            dataIndex: ['DMTransaction', 'RescheduleDate'],
            sorter: (a, b) => a.DMTransaction.RescheduleDate.localeCompare(b.DMTransaction.RescheduleDate),
            render: (value) => <p>{dayjs(value).format('YYYY-MM-DD')}</p>
        },
        {
            title: 'Location Name',
            dataIndex: 'LocationName',
            sorter: (a, b) => a.LocationName.localeCompare(b.LocationName),
            render: (_, record) => {
                if (!record.LocationName) {
                    return "-";
                }
                return record.LocationName;
            }
        },
        {
            title: 'Work Category',
            dataIndex: 'WorkCategory',
            sorter: (a, b) => a.WorkCategory.localeCompare(b.WorkCategory),
        },
        {
            title: 'Work SubCategory',
            dataIndex: 'WorkSubCategory',
            sorter: (a, b) => a.WorkSubCategory.localeCompare(b.WorkSubCategory),
        },
        {
            title: 'Status',
            dataIndex: ['DMTransaction', 'AppStatus'],
            sorter: (a, b) => a.DMTransaction.AppStatus.localeCompare(b.DMTransaction.AppStatus),
            render: (_, record) => {
                if (!record.DMTransaction.AppStatus) {
                    return "-";
                }
                return record.DMTransaction.AppStatus;
            }
        },
    ];

    if (myEditAuthIdx > 0 || myDeleteAuthIdx > 0) {
        columns.push({
            title: 'Action',
            key: 'Action',
            fixed: 'right',
            width: 100,
            render: (_, record) => {
                if (
                    record.DMTransaction.TranStatus !== 0
                ) {
                    return null;
                }
                return (
                    <Space>
                        {myEditAuthIdx > 0 && (
                            <EditDMTransaction data={record} onEdit={fetchData} />
                        )}
                        {myDeleteAuthIdx > 0 && (
                            <DeleteDMTransaction data={record.DMTransaction} onDelete={fetchData} />
                        )}
                    </Space>
                )
            },
        },
        )
    }

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredData = data.filter((item) =>
        Object.values(item).some(
            (val) => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
        )
    );

    const [selectedRows, setSelectedRows] = useState([]);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
            // console.log(selectedRows);
        },
    };

    const columnsExport = columns
        .filter((item) => item.key !== "Action" && item.key !== "key")
        .map((item) => {

            if (item.title === "Active") {
                return {
                    title: item.title,
                    key: item.dataIndex,
                    render: (record) => (record.DMTransaction.IsSuspend ? "Inactive" : "Active"),
                };
            }

            return {
                title: item.title,
                key: item.dataIndex,
            };
        });

    const processExportData = (data) =>
        data.map((item) => ({
            ...item,
            DMTransaction: {
                ...item.DMTransaction,
                IsSuspend: item.DMTransaction.IsSuspend ? "Inactive" : "Active",
            }
        }));

    return (
        <StyleContent>
            <StyleGridHeader>
                <Header
                    title="DEMAND"
                    subtitle="All Demand"
                />
                <Box display="flex" justifyContent="end" gap="8px">
                    {myPrintAuthIdx > 0 && (
                        <ExportData
                            data={processExportData(selectedRows.length > 0 ? selectedRows : data)}
                            columns={columnsExport}
                            name="DM Transaction"
                        />
                    )}

                    {myAddAuthIdx > 0 && (
                        <ButtonAddNew
                            link="form"
                        />
                    )}
                </Box>
            </StyleGridHeader>

            <SearchData
                filterValue={searchText}
                handleFilterChange={handleSearch}
            />

            <Table
                loading={isLoading}
                columns={columns}
                dataSource={filteredData}
                rowSelection={data.length === 0 ? null : rowSelection}
                expandable={{
                    expandedRowRender,
                }}
                pagination={{
                    showSizeChanger: true,
                    defaultPageSize: 10,
                }}
                scroll={{ x: 2500 }}
            />

        </StyleContent>
    );
};

export default DMTransaction