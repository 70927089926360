import MenuIcon from '@mui/icons-material/Menu';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import ApartmentIcon from '@mui/icons-material/Apartment';
import BusinessIcon from '@mui/icons-material/Business';
import MapIcon from '@mui/icons-material/Map';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import PinDropIcon from '@mui/icons-material/PinDrop';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import CategoryIcon from '@mui/icons-material/Category';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SpeedIcon from '@mui/icons-material/Speed';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';

import WindowIcon from '@mui/icons-material/Window';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import LanIcon from '@mui/icons-material/Lan';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

import PersonIcon from '@mui/icons-material/Person';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';


import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import HowToRegSharpIcon from '@mui/icons-material/HowToRegSharp';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';

export const MenuIcons = {
  // 1001: <MenuIcon />,
  1101: <MapsHomeWorkIcon />, //Facility
  1102: <ApartmentIcon />, //Building
  1103: <MapIcon />, //Map
  1104: <BusinessIcon />, //Floor Level
  1105: <InsertEmoticonIcon />, //Icon
  1106: <CategoryIcon />, //Category
  1107: <AutoAwesomeMosaicIcon />, //SubCategory
  1108: <PinDropIcon />, //Location
  1109: <SettingsIcon />, //Part
  1110: <DeviceHubIcon />, //Type
  1111: <HomeRepairServiceIcon />, //Equipment Model
  1112: <SettingsApplicationsIcon />, //Equipment
  1113: <DirectionsCarIcon />, //Vehicle
  1114: <CalendarMonthIcon />, //Schedule
  1115: <ContactPageIcon />, //Vendor
  1116: <SpeedIcon />, //Sensor
  // 1117: <ZoomOutMapIcon />, //Mapping

  // EMPLOYEE
  1201: <WindowIcon />, //Division
  1202: <ViewAgendaIcon />, //Department
  1203: <LanIcon />, //Section
  1204: <AssignmentIndIcon />, //Employee

  // SETTING
  1301: <PersonIcon />, //User
  1302: <PeopleAltIcon />, //User Group
  1303: <MenuOpenIcon />, //Tran Approver

  // TRANSACTION
  1401: <HowToRegSharpIcon />, //TranApproval
  1402: <ReceiptOutlinedIcon />, //WO Transaction
  1403: <ReceiptOutlinedIcon />, //DM Transaction
  1404: <ReceiptOutlinedIcon />, //PM Configuration
  1405: <ReceiptOutlinedIcon />, //MP Transaction
  1406: <ReceiptOutlinedIcon />, //WO Request
  1407: <DisplaySettingsIcon />, //Adjustment Transaction

  1501: <ReceiptOutlinedIcon />,
  1502: <ReceiptOutlinedIcon />,
  1503: <ReceiptOutlinedIcon />,
  1504: <ReceiptOutlinedIcon />,
  1505: <ReceiptOutlinedIcon />,
  1506: <ReceiptOutlinedIcon />,
  1507: <ReceiptOutlinedIcon />,
  1508: <ReceiptOutlinedIcon />,
  1509: <ReceiptOutlinedIcon />,
  1510: <ReceiptOutlinedIcon />,
  1511: <ReceiptOutlinedIcon />,
  1512: <ReceiptOutlinedIcon />,
};