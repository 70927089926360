export const columnsFacility = [
    {
        key: 'key',
        title: 'No',
        dataIndex: 'key',
        width: 80,
        fixed: 'left',
        sorter: (a, b) => a.key - b.key,
    },
    {
        key: 'FacilityCode',
        title: 'Facility Code',
        dataIndex: 'FacilityCode',
        width: 150,
        fixed: 'left',
        sorter: (a, b) => a.FacilityCode.localeCompare(b.FacilityCode),
    },
    {
        key: 'FacilityName',
        title: 'Facility Name',
        dataIndex: 'FacilityName',
        sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
    },
    {
        key: 'Address',
        title: 'Address',
        dataIndex: 'Address',
        width: 400,
        sorter: (a, b) => a.Address.localeCompare(b.Address),
    },
    {
        key: 'City',
        title: 'City',
        dataIndex: 'City',
        sorter: (a, b) => a.City.localeCompare(b.City),
    },
    {
        key: 'Country',
        title: 'Country',
        dataIndex: 'Country',
        sorter: (a, b) => a.Country.localeCompare(b.Country),
    },
    {
        key: 'Phone1',
        title: 'Phone Number 1',
        dataIndex: 'Phone1',
        sorter: (a, b) => a.Phone1.localeCompare(b.Phone1),
    },
    {
        key: 'Phone2',
        title: 'Phone Number 2',
        dataIndex: 'Phone2',
        sorter: (a, b) => a.Phone2.localeCompare(b.Phone2),
    },
    {
        key: 'Fax',
        title: 'Fax',
        dataIndex: 'Fax',
        sorter: (a, b) => a.Fax.localeCompare(b.Fax),
    },
    {
        key: 'ContactPerson',
        title: 'Contact Person',
        dataIndex: 'ContactPerson',
        sorter: (a, b) => a.ContactPerson.localeCompare(b.ContactPerson),
    },
];


export const columnsType = [
    {
        key: 'key',
        title: 'No',
        dataIndex: 'key',
        width: 80,
        fixed: 'left',
        sorter: (a, b) => a.key - b.key,
    },
    {
        title: 'Type Code',
        dataIndex: 'TypeCode',
        fixed: 'left',
        sorter: (a, b) => a.TypeCode.localeCompare(b.TypeCode),
    },
    {
        title: 'Type Name',
        dataIndex: 'TypeName',
        sorter: (a, b) => a.TypeName.localeCompare(b.TypeName),
    },
    {
        title: 'Module ID',
        dataIndex: 'ModuleID',
        sorter: (a, b) => a.ModuleID.localeCompare(b.ModuleID),
    },
]

export const columnsVehicleOwner = [
    {
        key: 'key',
        title: 'No',
        dataIndex: 'key',
        width: 80,
        fixed: 'left',
        sorter: (a, b) => a.key - b.key,
    },
    {
        title: 'Employee Name',
        dataIndex: ['Employee', 'EmpName'],
        sorter: (a, b) => a.Employee.EmpName.localeCompare(b.Employee.EmpName),
    },
    {
        title: 'Address',
        dataIndex: ['Employee', 'Address'],
        width: 400,
        sorter: (a, b) => a.Employee.Address.localeCompare(b.Employee.Address),
    },
    {
        title: 'Phone Number 1',
        dataIndex: ['Employee', 'Phone1'],
        sorter: (a, b) => a.Employee.Phone1.localeCompare(b.Employee.Phone1),
    },
    {
        title: 'Phone Number 2',
        dataIndex: ['Employee', 'Phone2'],
        sorter: (a, b) => a.Employee.Phone2.localeCompare(b.Employee.Phone2),
    },
    {
        title: 'NIK',
        dataIndex: ['Employee', 'NIK'],
        sorter: (a, b) => a.Employee.NIK.localeCompare(b.Employee.NIK),
    },
    {
        title: 'Division Name',
        dataIndex: 'DivisionName',
        sorter: (a, b) => a.DivisionName.localeCompare(b.DivisionName),
    },
    {
        title: 'Department Name',
        dataIndex: 'DepName',
        sorter: (a, b) => a.DepName.localeCompare(b.DepName),
    },
    {
        title: 'Section Name',
        dataIndex: 'SectionName',
        sorter: (a, b) => a.SectionName.localeCompare(b.SectionName),
    },
    {
        title: 'IsTechnician',
        dataIndex: ['Employee', 'IsTechnician'],
        sorter: (a, b) => a.Employee.IsTechnician.localeCompare(b.Employee.IsTechnician),
        render: (isTechnician) => (
            <span>
                {isTechnician ? 'Technician' : 'Not a technician'}
            </span>
        ),
    }
];