import React, { useCallback, useEffect, useState, useRef } from "react";
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import Authentication from "../../auth";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getMPMainTrasaction, getPMContactTrasaction, getPMDocumentTrasaction, getPMMainTrasaction, getPMResourceTrasaction, getPMSchduleTrasaction } from "../Api/GetData";
import { ButtonForm } from "../../global/components/ButtonAction";
import { PostMPTransaction } from "../Api/PostData";
import FormMPPart from "./MP_Part/form";
import FormMPRE from "./MP_RE/form";
import FormMPDoc from "./MP_Doc/form";
import FormMPCT from "./MP_CT/form";
import dayjs from "dayjs";
import StyleContent from "../../../components/Global/StyleContent";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import FormTranApproval from "../TranApproval/form";
import { StyleButtonForm } from "../../global/StyleCSS";
import { onlyNumber } from "../../global/Components";
import { getFacility } from "../../../Api/Master/GetData";
import InputModal from "../../../components/Global/InputModal";
import { columnsFacility } from "../PMTransaction/ColumnsPM";
import { columnsPM } from "./ColumnsMP";
import DataPMTask from "../PMTransaction/PM_Task";
import TranView from "../TranApproval/tranView";


const FormMPTransaction = () => {

    Authentication();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const action = searchParams.get('action');
    const faccode = searchParams.get('faccode');
    const trannumber = searchParams.get('trannumber');

    const [MPTransaction, setMPTransaction] = useState("");
    const [dataMP, setdataMP] = useState();
    const [isDisable, setIsDisable] = useState(false);
    const [onApproval, setOnApproval] = useState(false);

    useEffect(() => {
        if (faccode || trannumber) {
            const fetchMPTransaction = async () => {
                try {
                    const res = await getMPMainTrasaction(faccode, trannumber);
                    setMPTransaction(res[0].MPTransaction);
                    setdataMP(res[0]);
                } catch (error) {

                }
            }
            fetchMPTransaction();
        }
    }, [faccode, trannumber]);


    useEffect(() => {
        if (faccode && trannumber) {
            if (MPTransaction) {
                setIsDisable(true);
                setOnApproval(true);

                setOpenModalFacility(true);
                setOpenModalPM(true);
                // setOpenModalPMSchedule(true);

                setStartScheduleDate(dayjs(MPTransaction.StartScheduleDate));
                setFinishScheduleDate(dayjs(MPTransaction.FinishScheduleDate));
            }
        }
    }, [MPTransaction, dataMP, faccode, trannumber]);

    const formRef = useRef(null);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const [MPNumber, setMPNumber] = useState("");

    const [dataFacility, setDataFacility] = useState([]);
    const [selectFacility, setSelectFacility] = useState("");
    const [openModalFacility, setOpenModalFacility] = useState("");
    const FacilityCode = selectFacility ? selectFacility.FacilityCode : '';
    const FacilityName = selectFacility ? selectFacility.FacilityName : '';

    const [dataPM, setDataPM] = useState([]);
    const [selectPM, setSelectPM] = useState("");
    const [openModalPM, setOpenModalPM] = useState("");
    const PMNumber = selectPM ? selectPM.PMTransaction.PMNumber : '';
    const PMName = selectPM ? selectPM.PMTransaction.PMName : '';

    // const [dataPMSchedule, setDataPMSchedule] = useState([]);
    const [selectPMSchedule, setSelectPMSchedule] = useState("");
    // const [openModalPMSchedule, setOpenModalPMSchedule] = useState("");
    const PMScheduleCode = selectPMSchedule ? selectPMSchedule.ScheduleCode : '';
    const PMScheduleName = selectPMSchedule ? selectPMSchedule.ScheduleName : '';


    const [MPDate, setMPDate] = useState(dayjs());

    const [StartScheduleDate, setStartScheduleDate] = useState(null);
    const [FinishScheduleDate, setFinishScheduleDate] = useState(null);

    const [DueDate, setDueDate] = useState(null);

    const [dataPart, setDataPart] = useState([]);
    const [dataRE, setDataRE] = useState([]);
    const [dataDoc, setDataDoc] = useState([]);
    const [dataCT, setDataCT] = useState([]);

    const [dataPMRE, setDataPMRE] = useState([]);
    const [dataPMDoc, setDataPMDoc] = useState([]);
    const [dataPMCT, setDataPMCT] = useState([]);

    const [dataPMTask, setDataPMTask] = useState([]);

    // FACILITY
    useEffect(() => {
        const fetchFacility = async () => {
            try {
                const response = await getFacility();
                const filterRes = response.filter((item) => item.IsSuspend !== true);
                setDataFacility(filterRes);

                if (faccode && trannumber) {
                    const seleted = response.filter((item) => item.FacilityCode === faccode)
                    setSelectFacility(seleted[0])
                }

            } catch (error) {
                console.log(error);
            }
        }
        if (openModalFacility) {
            fetchFacility();
            setOpenModalFacility(false)
            setIsLoading(false);
        }
    }, [faccode, openModalFacility, trannumber]);


    // PM CONFIGRUTAION
    useEffect(() => {
        const fetchPM = async () => {
            try {
                const res = await getPMMainTrasaction(FacilityCode);
                const filter = res.filter((item) =>
                    item.PMTransaction.TranStatus === 2 &&
                    item.PMTransaction.IsClosed === false
                )
                setDataPM(filter);

                if ((faccode || trannumber) && MPTransaction) {
                    const selected = res.filter(item => item.PMTransaction.PMNumber === MPTransaction.PMNumber);
                    setSelectPM(selected[0]);
                }

            } catch (error) {
                console.log(error);
            }
        }

        if (openModalPM && FacilityCode) {
            fetchPM();
            setOpenModalPM(false);
            setIsLoading(false);
        }

    }, [FacilityCode, MPTransaction, faccode, openModalPM, trannumber]);


    // PM SCHEDULE
    useEffect(() => {
        const fetchPMSchedule = async () => {
            try {
                const res = await getPMSchduleTrasaction(FacilityCode, PMNumber);
                // setDataPMSchedule(res);

                // if ((faccode || trannumber) && MPTransaction) {
                const resSC = await getPMSchduleTrasaction(FacilityCode, PMNumber)
                const selected = res.filter(item => item.ScheduleCode === resSC[0].ScheduleCode);
                setSelectPMSchedule(selected[0]);
                // }

            } catch (error) {
                console.log(error);
            }
        }

        if (FacilityCode && PMNumber) {
            fetchPMSchedule();
            // setOpenModalPMSchedule(false);
            setIsLoading(false);
        }

    }, [FacilityCode, MPTransaction, PMNumber, faccode, trannumber]);

    // PM RESOURCE
    useEffect(() => {
        const fetchDataPMRE = async () => {
            try {
                const res = await getPMResourceTrasaction(FacilityCode, PMNumber);
                const filter = res.reverse();
                setDataPMRE(filter);
            } catch (error) {
                console.log(error);
            }
        }
        if (FacilityCode && PMNumber) {
            fetchDataPMRE();
        }
    }, [FacilityCode, PMNumber]);

    // PM DOCUMENT
    useEffect(() => {
        const fetchPMDocument = async () => {
            try {
                const res = await getPMDocumentTrasaction(FacilityCode, PMNumber);
                setDataPMDoc(res);
            } catch (error) {
                console.log(error);
            }
        }

        if (FacilityCode && PMNumber) {
            fetchPMDocument();
        }

    }, [FacilityCode, PMNumber]);


    // PM CONTACT
    useEffect(() => {
        const fetchPMContact = async () => {
            try {
                const res = await getPMContactTrasaction(FacilityCode, PMNumber);
                setDataPMCT(res);
            } catch (error) {
                console.log(error);
            }
        }

        if (FacilityCode && PMNumber) {
            fetchPMContact();
        }

    }, [FacilityCode, PMNumber]);

    useEffect(() => {
        if (formRef.current) {
            formRef.current.setFieldValue("FacilityName", FacilityName);
            formRef.current.setFieldValue("PMName", PMName);
            formRef.current.setFieldValue("PMSchedule", PMScheduleName);
            if (FacilityName && PMName && PMScheduleName && selectPM) {
                formRef.current.setFieldValue("RequestBy", selectPM.PMTransaction.RequestBy);
                formRef.current.setFieldValue("MPType", selectPM.PMTransaction.PMType);
                formRef.current.setFieldValue("LocationName", selectPM.LocationName ? selectPM.LocationName : '-');
                formRef.current.setFieldValue("WorkCatName", selectPM.WorkCategory);
                formRef.current.setFieldValue("WorkSubCatName", selectPM.WorkSubCategory);
                setDueDate(dayjs(selectPM.PMHistories[selectPM.PMHistories.length - 1].ScheduleDate))
            }
        }
    }, [FacilityName, PMName, PMScheduleName, selectPM]);



    // PM TASK
    useEffect(() => {
        if (FacilityCode && PMNumber) {
            setDataPMTask({
                FacilityCode: FacilityCode,
                PMNumber: PMNumber,
            })
        }
    }, [FacilityCode, PMNumber]);



    const FetchMPNumber = useCallback(async () => {
        try {
            const response = await getMPMainTrasaction(FacilityCode);
            if (response.length > 0) {
                const bcData = response.filter(item => item.MPTransaction.MPNumber && item.MPTransaction.MPNumber.startsWith('MPN'));
                if (bcData.length > 0) {
                    const lastCode = bcData[bcData.length - 1].MPTransaction.MPNumber;
                    const nextNumber = parseInt(lastCode.substr(3)) + 1;
                    setMPNumber(`MPN${nextNumber.toString().padStart(4, "0")}`);
                } else {
                    setMPNumber("MPN0001");
                }
            } else {
                setMPNumber("MPN0001");
            }
        } catch (error) {
            setMPNumber("MPN0001");
            console.log(error);
        }
    }, [FacilityCode]);


    useEffect(() => {
        if (FacilityCode && (!faccode || !trannumber)) {
            FetchMPNumber();
        }
    }, [FacilityCode, FetchMPNumber, faccode, trannumber]);

    useEffect(() => {
        if (formRef.current) {
            formRef.current.setFieldValue("MPNumber", MPNumber);
        }
    }, [MPNumber]);


    const handleCancel = () => {
        formRef.current.resetForm();
        setMPNumber('');
        setMPDate(null);
        setStartScheduleDate(null);
        setFinishScheduleDate(null);
        setDueDate(null);
    };

    const handleFormSubmit = async (values) => {
        try {
            setLoading(true)

            if (dataRE.length === 0) {
                enqueueSnackbar("Complete the detail MP transaction data form!", { variant: "warning" });
                setLoading(false);
                return;
            }

            const MPDateNow = MPDate.format('YYYY-MM-DD');
            const DueDateNow = DueDate.format('YYYY-MM-DD');
            const StartScheduleDateNow = StartScheduleDate.format('YYYY-MM-DD');
            const FinishScheduleDateNow = FinishScheduleDate.format('YYYY-MM-DD');

            const CurrentMeterReadingNum = parseInt(values.CurrentMeterReading, 10);

            const payload = {
                ...values,
                FacilityCode: FacilityCode,
                MPNumber: MPNumber,
                MPDate: MPDateNow,
                DueDate: DueDateNow,
                RequestBy: selectPM.PMTransaction.RequestBy,
                MPType: selectPM.PMTransaction.PMType,
                LocationCode: selectPM.PMTransaction.LocationCode,
                PMNumber: PMNumber,
                ScheduleCode: PMScheduleCode,
                CurrentMeterReading: CurrentMeterReadingNum,
                WorkCatCode: selectPM.PMTransaction.WorkCatCode,
                WorkSubCatCode: selectPM.PMTransaction.WorkSubCatCode,
                StartScheduleDate: StartScheduleDateNow,
                FinishScheduleDate: FinishScheduleDateNow,
                resources: dataRE,
                parts: dataPart,
                documents: dataDoc,
                contacts: dataCT,
            };

            console.log('Submit Data', payload);

            const response = await PostMPTransaction(payload);
            enqueueSnackbar(response.data.statusMessage, { variant: "success" });
            navigate("/transaction/maintenanceprocess");

        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.statusMessage
            ) {
                const errorMessage = error.response.data.statusMessage;
                enqueueSnackbar(errorMessage, { variant: "error" });
            } else {
                enqueueSnackbar("Data failed to save.", { variant: "error" });
            }
            console.error("error code", error.response);
        }

        setLoading(false)
    };

    const handleKeyPress = (e) => {
        if (!onlyNumber.test(e.key)) {
            e.preventDefault();
        }
    };

    const dataMPTransaction = {
        MPTransaction: MPTransaction,
        PMNumber: selectPM
    };

    // console.log(dataMPTransaction);

    return (
        <>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
                innerRef={formRef}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                }) => (
                    <Box>
                        <StyleContent>

                            <Header
                                title="MAINTENANCE PROCESS"
                                subtitle="Create a Maintenance Process"
                            />

                            <StyleForm>

                                <InputModal
                                    title="FACILITY"
                                    label="Facility Name"
                                    name="FacilityName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.FacilityName}
                                    error={!!touched.FacilityName && !!errors.FacilityName}
                                    helperText={touched.FacilityName && errors.FacilityName}
                                    dataSource={dataFacility}
                                    columns={columnsFacility}
                                    loading={isLoading}
                                    propsSelecteRow={(values) => setSelectFacility(values)}
                                    propsOpenModal={(values) => setOpenModalFacility(values)}
                                    LinktoCreate={`/master/facility/form`}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="MP Number*"
                                    onBlur={handleBlur}
                                    value={MPNumber}
                                    onChange={(e) => {
                                        handleChange(e)
                                        setMPNumber(e.target.value);
                                    }}
                                    name="MPNumber"
                                    error={!!touched.MPNumber && !!errors.MPNumber}
                                    helperText={touched.MPNumber && errors.MPNumber}
                                    sx={{ gridColumn: "span 2" }}
                                    inputProps={{ maxLength: 10 }}
                                    InputProps={{
                                        readOnly: isDisable
                                    }}
                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="MP Date*"
                                        value={MPDate}
                                        onBlur={handleBlur}
                                        onChange={(date) => setMPDate(date)}
                                        name="MPDate"
                                        error={!!touched.MPDate && !!errors.MPDate}
                                        helperText={touched.MPDate && errors.MPDate}
                                        sx={{ gridColumn: "span 2" }}
                                        readOnly={isDisable}
                                    />
                                </LocalizationProvider>

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="MP Status*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={MPTransaction ? (MPTransaction.MPStatus || values.MPStatus) : values.MPStatus}
                                    name="MPStatus"
                                    error={!!touched.MPStatus && !!errors.MPStatus}
                                    helperText={touched.MPStatus && errors.MPStatus}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: isDisable
                                    }}
                                />

                                <InputModal
                                    title="PM CONFIGURATION"
                                    label="PM Name"
                                    name="PMName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.PMName}
                                    error={!!touched.PMName && !!errors.PMName}
                                    helperText={touched.PMName && errors.PMName}
                                    dataSource={dataPM}
                                    columns={columnsPM}
                                    loading={isLoading}
                                    propsSelecteRow={(values) => setSelectPM(values)}
                                    propsOpenModal={(values) => setOpenModalPM(values)}
                                    LinktoCreate={`/transaction/pmconfiguration/form`}
                                />

                                {/* <InputModal
                                    title="PM SCHEDULE"
                                    label="PM Schedule"
                                    name="PMSchedule"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.PMSchedule}
                                    error={!!touched.PMSchedule && !!errors.PMSchedule}
                                    helperText={touched.PMSchedule && errors.PMSchedule}
                                    dataSource={dataPMSchedule}
                                    columns={columnsPMSchedule}
                                    loading={isLoading}
                                    propsSelecteRow={(values) => setSelectPMSchedule(values)}
                                    propsOpenModal={(values) => setOpenModalPMSchedule(values)}
                                    LinktoCreate={`/transaction/pmconfiguration/form`}
                                /> */}

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="PM Schedule*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.PMSchedule}
                                    name="PMSchedule"
                                    error={!!touched.PMSchedule && !!errors.PMSchedule}
                                    helperText={touched.PMSchedule && errors.PMSchedule}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Request By*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.RequestBy}
                                    name="RequestBy"
                                    error={!!touched.RequestBy && !!errors.RequestBy}
                                    helperText={touched.RequestBy && errors.RequestBy}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="MP Type*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.MPType}
                                    name="MPType"
                                    error={!!touched.MPType && !!errors.MPType}
                                    helperText={touched.MPType && errors.MPType}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Location Name*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.LocationName}
                                    name="LocationName"
                                    error={!!touched.LocationName && !!errors.LocationName}
                                    helperText={touched.LocationName && errors.LocationName}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Work Category Name*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.WorkCatName}
                                    name="WorkCatName"
                                    error={!!touched.WorkCatName && !!errors.WorkCatName}
                                    helperText={touched.WorkCatName && errors.WorkCatName}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Due Date"
                                        value={DueDate}
                                        onBlur={handleBlur}
                                        onChange={(date) => setDueDate(date)}
                                        name="DueDate"
                                        error={!!touched.DueDate && !!errors.DueDate}
                                        helperText={touched.DueDate && errors.DueDate}
                                        sx={{ gridColumn: "span 2" }}
                                        readOnly
                                    />
                                </LocalizationProvider>

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Current Meter Reading*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={MPTransaction ? (MPTransaction.CurrentMeterReading.toString() || values.CurrentMeterReading) : values.CurrentMeterReading}
                                    name="CurrentMeterReading"
                                    error={!!touched.CurrentMeterReading && !!errors.CurrentMeterReading}
                                    helperText={touched.CurrentMeterReading && errors.CurrentMeterReading}
                                    sx={{ gridColumn: "span 2" }}
                                    onKeyPress={handleKeyPress}
                                    InputProps={{
                                        readOnly: isDisable,
                                    }}
                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Start Schedule Date"
                                        value={StartScheduleDate}
                                        onBlur={handleBlur}
                                        onChange={(date) => setStartScheduleDate(date)}
                                        name="StartScheduleDate"
                                        error={!!touched.StartScheduleDate && !!errors.StartScheduleDate}
                                        helperText={touched.StartScheduleDate && errors.StartScheduleDate}
                                        sx={{ gridColumn: "span 2" }}
                                        readOnly={isDisable}
                                    />
                                </LocalizationProvider>

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Finish Schedule Date"
                                        value={FinishScheduleDate}
                                        onBlur={handleBlur}
                                        onChange={(date) => setFinishScheduleDate(date)}
                                        name="FinishScheduleDate"
                                        error={!!touched.FinishScheduleDate && !!errors.FinishScheduleDate}
                                        helperText={touched.FinishScheduleDate && errors.FinishScheduleDate}
                                        sx={{ gridColumn: "span 2" }}
                                        readOnly={isDisable}
                                        minDate={StartScheduleDate}
                                    />
                                </LocalizationProvider>

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Comments"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={MPTransaction ? (MPTransaction.Comments || values.Comments) : values.Comments}
                                    name="Comments"
                                    sx={{ gridColumn: "span 2" }}
                                    multiline
                                    rows={4}
                                    InputProps={{
                                        readOnly: isDisable
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Description"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={MPTransaction ? (MPTransaction.Description || values.Description) : values.Description}
                                    name="Description"
                                    sx={{ gridColumn: "span 2" }}
                                    multiline
                                    rows={4}
                                    InputProps={{
                                        readOnly: isDisable
                                    }}
                                />

                            </StyleForm>

                            <Box sx={{
                                margin: "20px 0",
                                padding: 2,
                                borderRadius: 2,
                                boxShadow: "0px 0px 10px #00000026",
                            }}>
                                <DataPMTask record={dataPMTask} />
                            </Box>

                            <Box sx={{
                                margin: "20px 0",
                                padding: 2,
                                borderRadius: 2,
                                boxShadow: "0px 0px 10px #00000026",
                            }}>
                                <FormMPRE
                                    onSaveData={(data) => setDataRE(data)}
                                    FacilityCode={FacilityCode}
                                    MPNumber={MPNumber}
                                    onEdit={false}
                                    onApproval={onApproval}
                                    PMRE={dataPMRE}
                                />
                            </Box>

                            <Box sx={{
                                margin: "20px 0",
                                padding: 2,
                                borderRadius: 2,
                                boxShadow: "0px 0px 10px #00000026",
                            }}>
                                <FormMPPart
                                    onSaveData={(data) => setDataPart(data)}
                                    FacilityCode={FacilityCode}
                                    MPNumber={MPNumber}
                                    onEdit={false}
                                    onApproval={onApproval}
                                />
                            </Box>

                            <Box sx={{
                                margin: "20px 0",
                                padding: 2,
                                borderRadius: 2,
                                boxShadow: "0px 0px 10px #00000026",
                            }}>
                                <FormMPDoc
                                    onSaveData={(data) => setDataDoc(data)}
                                    FacilityCode={FacilityCode}
                                    MPNumber={MPNumber}
                                    onEdit={false}
                                    onApproval={onApproval}
                                    PMDoc={dataPMDoc}
                                />
                            </Box>

                            <Box sx={{
                                margin: "20px 0",
                                padding: 2,
                                borderRadius: 2,
                                boxShadow: "0px 0px 10px #00000026",
                            }}>
                                <FormMPCT
                                    onSaveData={(data) => setDataCT(data)}
                                    FacilityCode={FacilityCode}
                                    MPNumber={MPNumber}
                                    onEdit={false}
                                    onApproval={onApproval}
                                    PMCT={dataPMCT}
                                />
                            </Box>

                        </StyleContent>

                        {
                            (faccode || trannumber) ? (
                                action === 'process' ? (
                                    <FormTranApproval dataMP={dataMPTransaction} />
                                ) : (
                                    <TranView />
                                )
                            ) : (
                                <ButtonForm
                                    handleCancel={handleCancel}
                                    handleSubmit={handleSubmit}
                                    loading={loading}
                                />
                            )
                        }

                    </Box>
                )}
            </Formik>
        </>
    );
};

const checkoutSchema = yup.object().shape({
    FacilityName: yup
        .string()
        .required("Please complete this Facility Name."),
    MPNumber: yup
        .string()
        .required("Please complete this MP Number."),
    MPStatus: yup
        .string()
        .max(30, "Maximum of 30 characters allowed")
        .required("Please complete this MP Status."),
    PMName: yup
        .string()
        .required("Please complete this PM Name."),
    PMSchedule: yup
        .string()
        .required("Please complete this PM Schedule."),
    RequestBy: yup
        .string()
        .max(30, "Maximum of 30 characters allowed")
        .required("Please complete this RequestBy."),
    CurrentMeterReading: yup
        .string()
        .required("Please complete this Current Meter Reading"),
});

const initialValues = {
    FacilityName: "",
    MPNumber: "",
    MPDate: "",
    MPStatus: "",
    PMName: "",
    PMSchedule: "",
    RequestBy: "",
    MPType: "",
    LocationName: "",
    WorkCatName: "",
    CurrentMeterReading: 0,
    Comments: "",
    Description: ""
};


export default FormMPTransaction;
