import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box, Typography } from "@mui/material";
import Authentication from "../../auth";
import { Space, Table, Tabs } from "antd";
import DataPMTask from "./PM_Task";
import DataPMRE from "./PM_RE";
import DataPMCT from "./PM_CT";
import DataPMDoc from "./PM_Doc";
import DataPMSC from "./PM_SC";
import ExportData from "../../global/components/FeatureTable/exportData";
import { ButtonAddNew } from "../../global/components/ButtonAction";
import SearchData from "../../global/components/FeatureTable/SearchData";
import { getPMMainTrasaction } from "../Api/GetData";
import EditPMTransaction from "./edit";
import DeletePMTransaction from "./delete";
import StyleContent from "../../../components/Global/StyleContent";
import AddAuthorityIdx from "../../../components/Global/Authority/AddAuthority";
import EditAuthorityIdx from "../../../components/Global/Authority/EditAuthority";
import DeleteAuthorityIdx from "../../../components/Global/Authority/DeleteAuthority";
import PrintAuthorityIdx from "../../../components/Global/Authority/PrintAuthority";
import dayjs from "dayjs";
import { toRupiah } from "../../../components/Global/Grid/DetailGrid";

const PMTransaction = () => {

    Authentication();

    const myAddAuthIdx = AddAuthorityIdx();
    const myEditAuthIdx = EditAuthorityIdx();
    const myDeleteAuthIdx = DeleteAuthorityIdx();
    const myPrintAuthIdx = PrintAuthorityIdx();

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState("");

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await getPMMainTrasaction();
            setData(response);
        } catch (error) {
            console.log(error.response.data);
        }
        setIsLoading(false)
    };

    useEffect(() => {
        fetchData();
    }, []);

    const expandedRowRender = (record) => {

        return (
            <Box sx={{ margin: "10px" }}>
                {record.PMTransaction.Description !== '' ?
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            marginBottom: "14px",
                            borderBottom: '2px solid #00000015'
                        }}
                    >
                        <Typography variant="h5" fontWeight={600}>Description : </Typography>
                        <Typography>{record.PMTransaction.Description}</Typography>
                    </div>
                    : ''
                }
                <Tabs
                    // onChange={onChange}
                    type="card"
                    items={[
                        {
                            label: 'Task',
                            key: 'task',
                            children: <DataPMTask record={record.PMTransaction} />,
                        },
                        {
                            label: 'Resource',
                            key: 'resource',
                            children: <DataPMRE record={record.PMTransaction} />,
                        },
                        {
                            label: 'Document',
                            key: 'document',
                            children: <DataPMDoc record={record.PMTransaction} />,
                        },
                        {
                            label: 'Contact',
                            key: 'contact',
                            children: <DataPMCT record={record.PMTransaction} />,
                        },
                        {
                            label: 'Schedule',
                            key: 'schedule',
                            children: <DataPMSC record={record.PMTransaction} />,
                        },
                    ]}
                />
            </Box>
        );
    };


    const columns = [
        {
            title: 'No',
            dataIndex: 'key',
            width: 100,
            fixed: 'left',
            sorter: (a, b) => a.key - b.key,
        },
        {
            title: 'FacilityName',
            dataIndex: 'FacilityName',
            sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
        },
        {
            title: 'PM Number',
            dataIndex: ['PMTransaction', 'PMNumber'],
            sorter: (a, b) => a.PMTransaction.PMNumber.localeCompare(b.PMTransaction.PMNumber),
        },
        {
            title: 'PM Name',
            dataIndex: ['PMTransaction', 'PMName'],
            sorter: (a, b) => a.PMTransaction.PMName.localeCompare(b.PMTransaction.PMName),
        },
        {
            title: 'PM Date',
            dataIndex: ['PMTransaction', 'PMDate'],
            sorter: (a, b) => a.PMTransaction.PMDate.localeCompare(b.PMTransaction.PMDate),
            render: (date) => <p>{dayjs(date).format('YYYY-MM-DD')}</p>
        },
        {
            title: 'Location Name',
            dataIndex: 'LocationName',
            sorter: (a, b) => a.LocationName.localeCompare(b.LocationName),
            render: (_, record) => {
                if (!record.LocationName) {
                    return "-";
                }
                return record.LocationName;
            }
        },
        {
            title: 'PM Type',
            dataIndex: ['PMTransaction', 'PMType'],
            sorter: (a, b) => a.PMTransaction.PMType.localeCompare(b.PMTransaction.PMType),
        },
        {
            title: 'PM Type Referensi',
            dataIndex: 'PMTypeRefName',
            sorter: (a, b) => a.PMTypeRefName.localeCompare(b.PMTypeRefName),
        },
        {
            title: 'Work Category',
            dataIndex: 'WorkCategory',
            sorter: (a, b) => a.WorkCategory.localeCompare(b.WorkCategory),
        },
        // {
        //     title: 'Work SubCategory',
        //     dataIndex: 'WorkSubCategory',
        //     sorter: (a, b) => a.WorkSubCategory.localeCompare(b.WorkSubCategory),
        // },
        {
            title: 'Schedule Type',
            dataIndex: ['PMTransaction', 'ScheduleType'],
            sorter: (a, b) => a.PMTransaction.ScheduleType.localeCompare(b.PMTransaction.ScheduleType),
        },
        {
            title: 'Schedule Start',
            dataIndex: ['PMTransaction', 'ScheduleStart'],
            sorter: (a, b) => a.PMTransaction.ScheduleStart.localeCompare(b.PMTransaction.ScheduleStart),
            render: (value) => <p>{dayjs(value).format(`YYYY-MM-DD`)}</p>
        },
        {
            title: 'Schedule End',
            dataIndex: ['PMTransaction', 'ScheduleEnd'],
            sorter: (a, b) => a.PMTransaction.ScheduleEnd.localeCompare(b.PMTransaction.ScheduleEnd),
            render: (value) => <p>{dayjs(value).format(`YYYY-MM-DD`)}</p>
        },
        {
            title: 'Day ToComplete',
            dataIndex: ['PMTransaction', 'DayToComplete'],
            sorter: (a, b) => a.PMTransaction.DayToComplete.localeCompare(b.PMTransaction.DayToComplete),
        },
        {
            title: 'Estimation Cost',
            dataIndex: ['PMTransaction', 'EstimationCost'],
            sorter: (a, b) => a.PMTransaction.EstimationCost.localeCompare(b.PMTransaction.EstimationCost),
            render: (value) => <p>{toRupiah(value)}</p>
        },
        {
            title: 'Status',
            dataIndex: ['PMTransaction', 'AppStatus'],
            sorter: (a, b) => a.PMTransaction.AppStatus.localeCompare(b.PMTransaction.AppStatus),
            render: (_, record) => {
                if (!record.PMTransaction.AppStatus) {
                    return "-";
                }
                return record.PMTransaction.AppStatus;
            }
        },
    ];

    if (myEditAuthIdx > 0 || myDeleteAuthIdx > 0) {
        columns.push({
            title: 'Action',
            key: 'Action',
            fixed: 'right',
            width: 100,
            render: (_, record) => {
                if (
                    record.PMTransaction.TranStatus !== 0
                ) {
                    return null;
                }
                return (
                    <Space>
                        {myEditAuthIdx > 0 && (
                            <EditPMTransaction data={record} onEdit={fetchData} />
                        )}

                        {myDeleteAuthIdx > 0 && (
                            <DeletePMTransaction data={record.PMTransaction} onDelete={fetchData} />
                        )}
                    </Space>
                );
            },
        })
    }

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredData = data.filter((item) =>
        Object.values(item).some(
            (val) => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
        )
    );

    const [selectedRows, setSelectedRows] = useState([]);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
            // console.log(selectedRows);
        },
    };

    const columnsExport = columns
        .filter((item) => item.key !== "Action" && item.key !== "key")
        .map((item) => {

            if (item.title === "Active") {
                return {
                    title: item.title,
                    key: item.dataIndex,
                    render: (record) => (record.PMTransaction.IsSuspend ? "Inactive" : "Active"),
                };
            }

            return {
                title: item.title,
                key: item.dataIndex,
            };
        });

    const processExportData = (data) =>
        data.map((item) => ({
            ...item,
            PMTransaction: {
                ...item.PMTransaction,
                IsSuspend: item.PMTransaction.IsSuspend ? "Inactive" : "Active",
            }
        }));

    return (
        <StyleContent>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Header
                    title="PM CONFIGURATION"
                    subtitle="All PM Configuration"
                />
                <Box display="flex" justifyContent="end" gap="8px">
                    {myPrintAuthIdx > 0 && (
                        <ExportData
                            data={processExportData(selectedRows.length > 0 ? selectedRows : data)}
                            columns={columnsExport}
                            name="PM Configuration"
                        />
                    )}

                    {myAddAuthIdx > 0 && (
                        <ButtonAddNew
                            link="form"
                        />
                    )}
                </Box>
            </Box>

            <SearchData
                filterValue={searchText}
                handleFilterChange={handleSearch}
            />

            <Table
                loading={isLoading}
                columns={columns}
                dataSource={filteredData}
                rowSelection={data.length === 0 ? null : rowSelection}
                expandable={{
                    expandedRowRender
                }}
                pagination={{
                    showSizeChanger: true,
                    defaultPageSize: 10,
                }}
                scroll={{ x: 3500 }}
            />

        </StyleContent>
    );
};

export default PMTransaction