import { Box, Button } from '@mui/material'
import React from 'react'
import { StyleButtonForm } from '../../global/StyleCSS'
import { useNavigate } from 'react-router-dom'


const TranView = () => {
  const navigate = useNavigate();
  return (
    <Box sx={StyleButtonForm}>
      {/* <Link to="/transaction/tranapproval" > */}
      <Button
        type="button"
        color="error"
        variant="contained"
        onClick={() => navigate(-1)}
      >
        <span>Cancel</span>
      </Button>
      {/* </Link> */}
    </Box>
  )
}

export default TranView