import { Box, IconButton, TextField, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useRef, useState } from 'react';
import { Modal } from 'antd';
import Header from '../../../components/Header';
import { ButtonUpdate } from '../../global/components/ButtonAction';
import { Formik } from 'formik';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getWOMainTrasaction } from '../Api/GetData';
import { updateDMTransaction } from '../Api/UpdateData';
import { useSnackbar } from 'notistack';
import FormDMRE from './DM_RE/form';
import FormDMPart from './DM_Part/form';
import FormDMDoc from './DM_Doc/form';
import FormDMCT from './DM_CT/form';
import dayjs from 'dayjs';
import StyleForm from '../../../components/Global/StyleContent/StyleForm';
import StyleEditHeader from '../../../components/Global/StyleContent/StyleEdit/StyleEditHeader';
import InputModal from '../../../components/Global/InputModal';
import { columnsWorkOrder } from './ColumunsDM';

const EditDMTransaction = ({ data, onEdit }) => {

    const dataEdit = data.DMTransaction;

    const formRef = useRef(null);
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [dataWorkOrder, setDataWorkOrder] = useState([]);
    const [selectWorkOrder, setSelectWorkOrder] = useState("");
    const WONumber = selectWorkOrder ? selectWorkOrder.WOTransaction.WONumber : '';
    const RequestBy = selectWorkOrder ? selectWorkOrder.WOTransaction.RequestBy : '';
    const RequestByName = selectWorkOrder ? selectWorkOrder.RequestName : '';
    const DMType = selectWorkOrder ? selectWorkOrder.WOTransaction.WOType : '';
    const LocationCode = selectWorkOrder ? selectWorkOrder.WOTransaction.LocationCode : '';
    // const LocationName = selectWorkOrder.LocationName ? selectWorkOrder.LocationName : '-';
    const LocationName = '-';
    const WorkCatCode = selectWorkOrder ? selectWorkOrder.WOTransaction.WorkCatCode : '';
    const WorkCategory = selectWorkOrder ? selectWorkOrder.WorkCategory : '';
    const WorkSubCatCode = selectWorkOrder ? selectWorkOrder.WOTransaction.WorkSubCatCode : '';
    const WorkSubCategory = selectWorkOrder ? selectWorkOrder.WorkSubCategory : '';
    const DueDateWO = selectWorkOrder ? selectWorkOrder.WOTransaction.DueDateWO : null;

    const [DueDate, setDueDate] = useState(null);

    const [DMDate, setDMDate] = useState(dayjs(dataEdit.DMDate));
    const [RescheduleDate, setRescheduleDate] = useState(dayjs(dataEdit.RescheduleDate));

    const [dataPart, setDataPart] = useState([]);
    const [dataRE, setDataRE] = useState([]);
    const [dataDoc, setDataDoc] = useState([]);
    const [dataCT, setDataCT] = useState([]);


    // WORK ODER
    useEffect(() => {
        const fetchWorkOrder = async () => {
            try {
                const res = await getWOMainTrasaction(dataEdit.FacilityCode);

                const filterRes = res.filter((item) =>
                    item.WOTransaction.TranStatus === 2
                )
                setDataWorkOrder(filterRes);

                const selected = res.filter(item => item.WOTransaction.WONumber === dataEdit.WONumber);
                setSelectWorkOrder(selected[0]);      


            } catch (error) {
                console.log(error);
            }
        }

        if (open) {
            fetchWorkOrder();
            setIsLoading(false);
        }

    }, [dataEdit.FacilityCode, dataEdit.WONumber, open]);


    useEffect(() => {
        if (formRef.current) {
            formRef.current.setFieldValue("WONumber", WONumber);
            formRef.current.setFieldValue("RequestBy", RequestByName);
            formRef.current.setFieldValue("DMType", DMType);
            formRef.current.setFieldValue("LocationName", LocationName);
            formRef.current.setFieldValue("WorkCategory", WorkCategory);
            formRef.current.setFieldValue("WorkSubCategory", WorkSubCategory);
        }
        if (selectWorkOrder) {
            setDueDate(dayjs(DueDateWO))
        }
    }, [DMType, DueDateWO, LocationName, RequestByName, WONumber, WorkCategory, WorkSubCategory, selectWorkOrder]);





    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        formRef.current.resetForm();
        setOpen(false);
    };

    const handleFormSubmit = async (values) => {
        // setLoading(true)
        try {

            // if (dataRE.length === 0 || dataPart.length === 0 || dataDoc.length === 0 || dataCT.length === 0) {
            //     enqueueSnackbar("Complete the detail Demand data form!", { variant: "warning" });
            //     setLoading(false);
            //     return;
            // }

            const DMDateNow = DMDate.format('YYYY-MM-DD');
            const DueDateNow = DueDate.format('YYYY-MM-DD');
            const RescheduleDateNow = RescheduleDate.format('YYYY-MM-DD');

            const modifiedValues = {
                ...values,
                DMDate: DMDateNow,
                DueDate: DueDateNow,
                RequestBy: RequestBy,
                DMType: DMType,
                LocationCode: LocationCode,
                WorkCatCode: WorkCatCode,
                WorkSubCatCode: WorkSubCatCode,
                RescheduleDate: RescheduleDateNow,
                resources: dataRE,
                parts: dataPart,
                documents: dataDoc,
                contacts: dataCT,
            };

            console.log('Update Data', modifiedValues);

            const response = await updateDMTransaction(dataEdit.FacilityCode, dataEdit.DMNumber, modifiedValues);
            enqueueSnackbar(response.data.statusMessage, { variant: "success" });
            // onEdit(dataEdit.FacilityCode && dataEdit.DMNumber);
            // setOpen(false);

        } catch (error) {
            if (error.response && error.response.data && error.response.data.statusMessage) {
                const errorMessage = error.response.data.statusMessage;
                enqueueSnackbar(errorMessage, { variant: "error" });
            } else {
                enqueueSnackbar("Data failed to save.", { variant: "error" });
            }
            console.error("error code", error.response.data);
        }

        setLoading(false)
    };

    return (
        <Box>
            <Tooltip title="Edit" fontSize="large">
                <IconButton onClick={handleOpen}>
                    <EditIcon />
                </IconButton>
            </Tooltip>

            <Box>

                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={dataEdit}
                    // validationSchema={checkoutSchema}
                    innerRef={formRef}
                >

                    {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                    }) => (
                        <Modal
                            title={
                                <StyleEditHeader>
                                    <Header
                                        title="DEMAND"
                                        subtitle="Edit Data a Demand"
                                    />
                                </StyleEditHeader>
                            }
                            centered
                            open={open}
                            closable={false}
                            style={{
                                maxWidth: "90%",
                                minWidth: "80%",
                            }}
                            bodyStyle={{
                                maxHeight: "65vh",
                                overflow: "auto",
                            }}
                            footer={() => (
                                <ButtonUpdate
                                    handleClose={handleClose}
                                    handleSubmit={handleSubmit}
                                    loading={loading}
                                />
                            )}
                        >

                            <StyleForm>

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Facility Name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={data.FacilityName}
                                    name="FacilityCode"
                                    error={!!touched.FacilityCode && !!errors.FacilityCode}
                                    helperText={touched.FacilityCode && errors.FacilityCode}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="DM Number*"
                                    onBlur={handleBlur}
                                    value={values.DMNumber}
                                    onChange={handleChange}
                                    name="DMNumber"
                                    error={!!touched.DMNumber && !!errors.DMNumber}
                                    helperText={!!touched.DMNumber && !!errors.DMNumber}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="DM Date*"
                                        value={DMDate}
                                        onBlur={handleBlur}
                                        onChange={(date) => setDMDate(date)}
                                        name="DMDate"
                                        error={!!touched.DMDate && !!errors.DMDate}
                                        helperText={touched.DMDate && errors.DMDate}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                </LocalizationProvider>

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="DM Status*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.DMStatus}
                                    name="DMStatus"
                                    error={!!touched.DMStatus && !!errors.DMStatus}
                                    helperText={touched.DMStatus && errors.DMStatus}
                                    sx={{ gridColumn: "span 2" }}
                                />

                                <InputModal
                                    title="WORK ORDER"
                                    label="Work Order"
                                    name="WONumber"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.WONumber}
                                    error={!!touched.WONumber && !!errors.WONumber}
                                    helperText={touched.WONumber && errors.WONumber}
                                    dataSource={dataWorkOrder}
                                    columns={columnsWorkOrder}
                                    loading={isLoading}
                                    propsSelecteRow={(values) => setSelectWorkOrder(values)}
                                    // propsOpenModal={(values) => setOpenModalWorkOrder(values)}
                                    LinktoCreate={`/transaction/workorder/form`}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Request By*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.RequestBy}
                                    name="RequestBy"
                                    error={!!touched.RequestBy && !!errors.RequestBy}
                                    helperText={touched.RequestBy && errors.RequestBy}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="DM Type*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.DMType}
                                    name="DMType"
                                    error={!!touched.DMType && !!errors.DMType}
                                    helperText={touched.DMType && errors.DMType}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Location Name*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.LocationName}
                                    name="LocationName"
                                    error={!!touched.LocationName && !!errors.LocationName}
                                    helperText={touched.LocationName && errors.LocationName}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Work Category Name*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.WorkCategory}
                                    name="WorkCategory"
                                    error={!!touched.WorkCategory && !!errors.WorkCategory}
                                    helperText={touched.WorkCategory && errors.WorkCategory}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Work SubCategory Name*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.WorkSubCategory}
                                    name="WorkSubCategory"
                                    error={!!touched.WorkSubCategory && !!errors.WorkSubCategory}
                                    helperText={touched.WorkSubCategory && errors.WorkSubCategory}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />


                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Due Date"
                                        value={DueDate}
                                        onBlur={handleBlur}
                                        onChange={(date) => setDueDate(date)}
                                        name="DueDate"
                                        error={!!touched.DueDate && !!errors.DueDate}
                                        helperText={touched.DueDate && errors.DueDate}
                                        sx={{ gridColumn: "span 2" }}
                                        readOnly={true}
                                    />
                                </LocalizationProvider>

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Reschedule Date"
                                        value={RescheduleDate}
                                        onBlur={handleBlur}
                                        onChange={(date) => setRescheduleDate(date)}
                                        name="RescheduleDate"
                                        error={!!touched.RescheduleDate && !!errors.RescheduleDate}
                                        helperText={touched.RescheduleDate && errors.RescheduleDate}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                </LocalizationProvider>

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Comments"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.Comments}
                                    name="Comments"
                                    sx={{ gridColumn: "span 2" }}
                                    multiline
                                    rows={4}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Description"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.Description}
                                    name="Description"
                                    sx={{ gridColumn: "span 2" }}
                                    multiline
                                    rows={4}
                                />

                            </StyleForm>

                            <Box sx={{ px: 3, }}>

                                <Box sx={{
                                    margin: "20px 0",
                                    padding: 2,
                                    borderRadius: 2,
                                    boxShadow: "0px 0px 10px #00000026",
                                }}>
                                    <FormDMRE
                                        onSaveData={(data) => setDataRE(data)}
                                        FacilityCode={dataEdit.FacilityCode}
                                        DMNumber={dataEdit.DMNumber}
                                        onEdit={true}
                                    />
                                </Box>

                                <Box sx={{
                                    margin: "20px 0",
                                    padding: 2,
                                    borderRadius: 2,
                                    boxShadow: "0px 0px 10px #00000026",
                                }}>
                                    <FormDMPart
                                        onSaveData={(data) => setDataPart(data)}
                                        FacilityCode={dataEdit.FacilityCode}
                                        DMNumber={dataEdit.DMNumber}
                                        onEdit={true}
                                    />
                                </Box>

                                <Box sx={{
                                    margin: "20px 0",
                                    padding: 2,
                                    borderRadius: 2,
                                    boxShadow: "0px 0px 10px #00000026",
                                }}>
                                    <FormDMDoc
                                        onSaveData={(data) => setDataDoc(data)}
                                        FacilityCode={dataEdit.FacilityCode}
                                        DMNumber={dataEdit.DMNumber}
                                        onEdit={true}
                                    />
                                </Box>

                                <Box sx={{
                                    margin: "20px 0",
                                    padding: 2,
                                    borderRadius: 2,
                                    boxShadow: "0px 0px 10px #00000026",
                                }}>
                                    <FormDMCT
                                        onSaveData={(data) => setDataCT(data)}
                                        FacilityCode={dataEdit.FacilityCode}
                                        DMNumber={dataEdit.DMNumber}
                                        onEdit={true}
                                    />
                                </Box>

                            </Box>

                        </Modal>
                    )}
                </Formik>
            </Box>
        </Box >

    )
};

export default EditDMTransaction