import { Image } from "antd";

export const columnsSensorType = [
    {
        title: 'Type Code',
        dataIndex: 'TypeCode',
        fixed: 'left',
        sorter: (a, b) => a.TypeCode.localeCompare(b.TypeCode),
    },
    {
        title: 'Type Name',
        dataIndex: 'TypeName',
        sorter: (a, b) => a.TypeName.localeCompare(b.TypeName),
    },
    {
        title: 'Module ID',
        dataIndex: 'ModuleID',
        sorter: (a, b) => a.ModuleID.localeCompare(b.ModuleID),
    },
];


export const columnsWarningIcon = [
    {
        key: 'key',
        title: 'No',
        dataIndex: 'key',
        width: 80,
        fixed: 'left',
        sorter: (a, b) => a.key - b.key,
    },
    {
        title: 'Image',
        dataIndex: 'Image',
        render: (params) =>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Image
                    style={{ maxWidth: 50, maxHeight: "100%", objectFit: "contain" }}
                    src={`${process.env.REACT_APP_BASEURL_IMG}/${params}`}
                />
            </div>
    },
    {
        title: 'Icon Id',
        dataIndex: 'IconId',
        sorter: (a, b) => a.IconId.localeCompare(b.IconId),
    },
    {
        title: 'Icon Name',
        dataIndex: 'IconName',
        sorter: (a, b) => a.IconName.localeCompare(b.IconName),
    },
];


export const columnsAssetObject = [
    {
        title: 'Sensor Type',
        dataIndex: 'SensorType',
        sorter: (a, b) => a.SensorType.localeCompare(b.SensorType),
    },
    {
        title: 'Sensor Code',
        dataIndex: 'SensorCode',
        sorter: (a, b) => a.SensorCode.localeCompare(b.SensorCode),
    },
    {
        title: 'Sensor Name',
        dataIndex: 'SensorName',
        sorter: (a, b) => a.SensorName.localeCompare(b.SensorName),
    },
]