import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { getTechnicianPerformance } from "../API";

const TechnicianPerformance = () => {

    const [dataSource, setDataSource] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getTechnicianPerformance();
                setDataSource(res);
            } catch (error) {
                console.log(error);
            }
        }

        fetchData();

    }, []);    

    const data = dataSource.length > 0
        ? [
            ["Employee", "Total Work", "Total On Track", "Total On Time"],
            ...dataSource.map((item) => [
                item.EmpName,
                item.TotalWork,
                item.TotalOnTrack,
                item.TotalOnTime,
            ])
        ] :
        [
            ["Employee", "Total Work", "Total On Track", "Total On Time"],

            ["No Data", 0, 0, 0],
        ];

    const options = {
        // width: 650,
        // title: "Nearby galaxies",
        // curveType: "function",
        // seriesType: "bars",
        // series: {
        //     3: { targetAxisIndex: 1 }
        // },
        // vAxes: {
        //     // Adds titles to each axis.
        //     0: { title: "primary", format: "currency" },
        //     1: { title: "secondary" }
        // },
        legend: { position: "bottom" }
    };

    return (
        <Chart
            chartType="ColumnChart"
            width="100%"
            height="300px"
            data={data}
            options={options}
            legendToggle
        />
    );
}

export default TechnicianPerformance