import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import { Box } from "@mui/material";
import { Table } from "antd";
import SearchData from "../../global/components/FeatureTable/SearchData";
import StyleContent from "../../../components/Global/StyleContent";
import { getMPStatus } from "../../../Api/Report/GetData";
import dayjs from "dayjs";

const MPTransactionStatus = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getMPStatus();
        setData(res);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
    setIsLoading(false);
  }, []);

  const columns = [
    {
      title: 'No',
      dataIndex: 'key',
      width: 100,
      fixed: 'left',
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: 'Facility Name',
      dataIndex: 'FacilityName',
      sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
    },
    {
      title: 'MP Number',
      dataIndex: 'MPNumber',
      sorter: (a, b) => a.MPNumber.localeCompare(b.MPNumber),
    },
    {
      title: 'MP Date',
      dataIndex: 'MPDate',
      sorter: (a, b) => a.MPDate.localeCompare(b.MPDate),
    },
    {
      title: 'Location Name',
      dataIndex: 'LocationName',
      sorter: (a, b) => a.LocationName.localeCompare(b.LocationName),
    },
    {
      title: 'MP Status',
      dataIndex: 'MPStatus',
      sorter: (a, b) => a.MPStatus.localeCompare(b.MPStatus),
    },
    {
      title: 'MP Type',
      dataIndex: 'MPType',
      sorter: (a, b) => a.MPType.localeCompare(b.MPType),
    },
    {
      title: 'Due Date',
      dataIndex: 'DueDate',
      sorter: (a, b) => a.DueDate.localeCompare(b.DueDate),
    },
    {
      title: 'Current Meter Reading',
      dataIndex: 'CurrentMeterReading',
      sorter: (a, b) => a.CurrentMeterReading.localeCompare(b.CurrentMeterReading),
        render: (date) => <p>{dayjs(date).format('YYYY-MM-DD')}</p>
    },
    {
      title: 'Maintenance Start',
      dataIndex: 'StartScheduleDate',
      sorter: (a, b) => a.StartScheduleDate.localeCompare(b.StartScheduleDate),
        render: (date) => <p>{dayjs(date).format('YYYY-MM-DD')}</p>
    },
    {
      title: 'Maintenance Finish',
      dataIndex: 'FinishScheduleDate',
      sorter: (a, b) => a.FinishScheduleDate.localeCompare(b.FinishScheduleDate),
        render: (date) => <p>{dayjs(date).format('YYYY-MM-DD')}</p>
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      sorter: (a, b) => a.Status.localeCompare(b.Status),
      render: (value) =>
        value ? value : '-'
    },
  ];

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some((val) =>
      val && val.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  return (
    <StyleContent>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Header
          title="MAINTENANCE PROCESS STATUS"
          subtitle="All MP Maintenance Process Status"
        />
      </Box>

      <SearchData
        filterValue={searchText}
        handleFilterChange={handleSearch}
      />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={filteredData}
        // rowSelection={data.length === 0 ? null : rowSelection}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        scroll={{ x: 2000 }}
      />

    </StyleContent>
  );
};

export default MPTransactionStatus