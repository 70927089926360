import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box, Typography } from "@mui/material";
import Authentication from "../../auth";
import { Space, Table, Tabs } from "antd";
import DataWORE from "./WO_RE";
import DataWOCT from "./WO_CT";
import DataWODoc from "./WO_Doc";
import ExportData from "../../global/components/FeatureTable/exportData";
import { ButtonAddNew } from "../../global/components/ButtonAction";
import SearchData from "../../global/components/FeatureTable/SearchData";
import { getWOMainTrasaction } from "../Api/GetData";
import EditWOTransaction from "./edit";
import DeleteWOTransaction from "./delete";
import StyleContent from "../../../components/Global/StyleContent";
import StyleGridHeader from "../../../components/Global/StyleContent/StyleGrid/StyleGridHeader";
import AddAuthorityIdx from "../../../components/Global/Authority/AddAuthority";
import EditAuthorityIdx from "../../../components/Global/Authority/EditAuthority";
import DeleteAuthorityIdx from "../../../components/Global/Authority/DeleteAuthority";
import PrintAuthorityIdx from "../../../components/Global/Authority/PrintAuthority";
import dayjs from "dayjs";

const WOTransaction = () => {

    Authentication();

    const myAddAuthIdx = AddAuthorityIdx();
    const myEditAuthIdx = EditAuthorityIdx();
    const myDeleteAuthIdx = DeleteAuthorityIdx();
    const myPrintAuthIdx = PrintAuthorityIdx();

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState('');

    
    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await getWOMainTrasaction();
            setData(response);
        } catch (error) {
            console.log(error.response.data);
        }
        setIsLoading(false)
    };

    useEffect(() => {
        fetchData();
    }, []);

    const expandedRowRender = (record) => {

        return (
            <Box sx={{ margin: "10px" }}>
                {record.WOTransaction.Description !== '' ?
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            marginBottom: "14px",
                            borderBottom: '2px solid #00000015'
                        }}
                    >
                        <Typography variant="h5" fontWeight={600}>Description : </Typography>
                        <Typography>{record.WOTransaction.Description}</Typography>
                    </div>
                    : ''
                }
                <Tabs
                    // onChange={onChange}
                    type="card"
                    items={[
                        {
                            label: 'Resource',
                            key: 'resource',
                            children: <DataWORE record={record.WOTransaction} />,
                        },
                        {
                            label: 'Document',
                            key: 'document',
                            children: <DataWODoc record={record.WOTransaction} />,
                        },
                        {
                            label: 'Contact',
                            key: 'contact',
                            children: <DataWOCT record={record.WOTransaction} />,
                        },
                    ]}
                />
            </Box>
        );
    };


    const columns = [
        {
            title: 'No',
            dataIndex: 'key',
            width: 100,
            fixed: 'left',
            sorter: (a, b) => a.key - b.key,
        },
        {
            title: 'FacilityName',
            dataIndex: 'FacilityName',
            sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
        },
        {
            title: 'WO Number',
            dataIndex: ['WOTransaction', 'WONumber'],
            sorter: (a, b) => a.WOTransaction.WONumber.localeCompare(b.WOTransaction.WONumber),
        },
        {
            title: 'WO Date',
            dataIndex: ['WOTransaction', 'WODate'],
            sorter: (a, b) => a.WOTransaction.WODate.localeCompare(b.WOTransaction.WODate),
            render: (text) => <p>{dayjs(text).format('YYYY-MM-DD')}</p>
        },
        {
            title: 'Request By',
            dataIndex: 'RequestName',
            sorter: (a, b) => a.RequestName.localeCompare(b.RequestName),
        },
        {
            title: 'Location Name',
            dataIndex: 'LocationName',
            sorter: (a, b) => a.LocationName.localeCompare(b.LocationName),
            render: (_, record) => {
                if (!record.LocationName) {
                    return "-";
                }
                return record.LocationName;
            }
        },
        {
            title: 'WO Type',
            dataIndex: ['WOTransaction', 'WOType'],
            sorter: (a, b) => a.WOTransaction.WOType.localeCompare(b.WOTransaction.WOType),
        },
        {
            title: 'WO Request Number',
            dataIndex: ['WOTransaction', 'WORNumber'],
            sorter: (a, b) => a.WOTransaction.WORNumber.localeCompare(b.WOTransaction.WORNumber),
        },
        {
            title: 'Work Category',
            dataIndex: 'WorkCategory',
            sorter: (a, b) => a.WorkCategory.localeCompare(b.WorkCategory),
        },
        {
            title: 'Work SubCategory',
            dataIndex: 'WorkSubCategory',
            sorter: (a, b) => a.WorkSubCategory.localeCompare(b.WorkSubCategory),
        },
        {
            title: 'Due Date',
            dataIndex: ['WOTransaction', 'DueDateWO'],
            sorter: (a, b) => a.WOTransaction.DueDateWO.localeCompare(b.WOTransaction.DueDateWO),
            render: (text) => <p>{dayjs(text).format('YYYY-MM-DD')}</p>
        },
        {
            title: 'Status',
            dataIndex: ['WOTransaction', 'AppStatus'],
            sorter: (a, b) => a.WOTransaction.AppStatus.localeCompare(b.WOTransaction.AppStatus),
            render: (_, record) => {
                if (!record.WOTransaction.AppStatus) {
                    return "-";
                }
                return record.WOTransaction.AppStatus;
            }
        },
    ];


    if (myEditAuthIdx > 0 || myDeleteAuthIdx > 0) {
        columns.push({
            title: 'Action',
            key: 'Action',
            fixed: 'right',
            width: 100,
            render: (_, record) => {
                if (
                    record.WOTransaction.TranStatus !== 0
                ) {
                    return null;
                }
                return (
                    <Space>
                        {myEditAuthIdx > 0 && (
                            <EditWOTransaction data={record} onEdit={fetchData} />
                        )}

                        {myDeleteAuthIdx > 0 && (
                            <DeleteWOTransaction data={record} onDelete={fetchData} />
                        )}
                    </Space>
                );
            },
        })
    }


    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredData = data.filter((item) =>
        Object.values(item).some(
            (val) => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
        )
    );

    const [selectedRows, setSelectedRows] = useState([]);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
            // console.log(selectedRows);
        },
    };

    const columnsExport = columns
        .filter((item) => item.key !== "Action" && item.key !== "key")
        .map((item) => {

            if (item.title === "Active") {
                return {
                    title: item.title,
                    key: item.dataIndex,
                    render: (record) => (record.WOTransaction.IsSuspend ? "Inactive" : "Active"),
                };
            }

            return {
                title: item.title,
                key: item.dataIndex,
            };
        });

    const processExportData = (data) =>
        data.map((item) => ({
            ...item,
            WOTransaction: {
                ...item.WOTransaction,
                IsSuspend: item.WOTransaction.IsSuspend ? "Inactive" : "Active",
            }
        }));

    return (
        <StyleContent>
            <StyleGridHeader>
                <Header
                    title="WORK ORDER"
                    subtitle="All Work Order"
                />
                <Box display="flex" justifyContent="end" gap="8px">
                    {myPrintAuthIdx > 0 && (
                        <ExportData
                            data={processExportData(selectedRows.length > 0 ? selectedRows : data)}
                            columns={columnsExport}
                            name="WO Transaction"
                        />
                    )}

                    {myAddAuthIdx > 0 && (
                        <ButtonAddNew
                            link="form"
                        />
                    )}
                </Box>
            </StyleGridHeader>

            <SearchData
                filterValue={searchText}
                handleFilterChange={handleSearch}
            />

            <Table
                loading={isLoading}
                columns={columns}
                dataSource={filteredData}
                rowSelection={data.length === 0 ? null : rowSelection}
                expandable={{
                    expandedRowRender
                }}
                pagination={{
                    showSizeChanger: true,
                    defaultPageSize: 10,
                }}
                scroll={{ x: 2500 }}
            />

        </StyleContent>
    );
};

export default WOTransaction