import { Route, Routes } from "react-router-dom";

import Dashboard from "../pages/Dashboard";

// MASTER DATA
















// EMPLOYEE





// CONFIGURATION




// TRANSACTION

import AddAuthorityIdx from "../components/Global/Authority/AddAuthority";
import { useEffect, useState } from "react";
import { getTran } from "../Api/Configuration/GetData";
import { ViewTran } from "./ViewTran";
import { FormTranMenu } from "./FormTran";
import Summary from "../pages/Dashboard/Summary";
import SummaryPreventive from "../pages/Dashboard/Summary/Preventive";
import SummaryDemand from "../pages/Dashboard/Summary/Demand";

const RouteDashboard = () => {
    // const { authIdx } = useAuthContext();
    const myAddAuthIdx = AddAuthorityIdx();

    const master = "/master";
    // const employee = "/employee";
    // const setting = "/setting";
    const transaction = "/transaction";

    const [pageTran, setPageTran] = useState([]);
    const [pageFormTran, setPageFormTran] = useState([]);

    useEffect(() => {
        const fetchTran = async () => {
            try {
                const res = await getTran();
                setPageTran(res.map((row, index) => ({ ...row, Page: ViewTran[row.TranIdx] })));
                setPageFormTran(res.map((row, index) => ({ ...row, Page: FormTranMenu[row.TranIdx] })));
            } catch (error) {
                console.log(error);
            }
        }
        fetchTran();
    }, []);

    const mainRoutes = [
        { path: "/dashboard", element: <Dashboard /> },
        { path: "/dashboard/summary/:status/:faccode/:buildcode", element: <Summary /> },
        { path: "/dashboard/summary/preventive/:faccode/:buildcode", element: <SummaryPreventive /> },
        { path: "/dashboard/summary/demand/:faccode/:buildcode", element: <SummaryDemand /> },

        ...pageTran.map((item) => ({
            path: `/${item.TranType.toLowerCase()}/${item.TranFormName.toLowerCase()}`,
            element: item.Page,
        })),

        ...pageFormTran.map((item) => ({
            path: `/${item.TranType.toLowerCase()}/${item.TranFormName.toLowerCase()}/form`,
            element: item.Page,
        })),

        // { path: `${master}/facility`, element: <Facility /> },
        // ...(myAddAuthIdx
        //     ? [{ path: `${master}/facility/form`, element: <FormFacility /> }]
        //     : [{ path: `${master}/facility/form`, element: <Facility /> }]),

        // { path: `${master}/building`, element: <Buildings /> },
        // ...(myAddAuthIdx
        //     ? [{ path: `${master}/building/form`, element: <FormBuildings /> }]
        //     : [{ path: `${master}/building/form`, element: <Buildings /> }]),

        // { path: `${master}/floorlevel`, element: <Floor /> },
        // ...(myAddAuthIdx
        //     ? [{ path: `${master}/floorlevel/form`, element: <FormFloor /> }]
        //     : [{ path: `${master}/floorlevel/form`, element: <Floor /> }]),

        // { path: `${master}/type`, element: <Type /> },
        // ...(myAddAuthIdx
        //     ? [{ path: `${master}/type/form`, element: <FormType /> }]
        //     : [{ path: `${master}/type/form`, element: <Type /> }]),

        // { path: `${master}/equipmentmodel`, element: <EquipmentModel /> },
        // ...(myAddAuthIdx
        //     ? [
        //         {
        //             path: `${master}/equipmentmodel/form`,
        //             element: <FormEquipmentModel />,
        //         },
        //     ]
        //     : [
        //         {
        //             path: `${master}/equipmentmodel/form`,
        //             element: <EquipmentModel />,
        //         },
        //     ]),

        // { path: `${master}/equipment`, element: <Equipment /> },
        // ...(myAddAuthIdx
        //     ? [{ path: `${master}/equipment/form`, element: <FormEquipment /> }]
        //     : [{ path: `${master}/equipment/form`, element: <Equipment /> }]),

        // { path: `${master}/category`, element: <Category /> },
        // ...(myAddAuthIdx
        //     ? [{ path: `${master}/category/form`, element: <FormCategory /> }]
        //     : [{ path: `${master}/category/form`, element: <Category /> }]),

        // { path: `${master}/subcategory`, element: <SubCategory /> },
        // ...(myAddAuthIdx
        //     ? [{ path: `${master}/subcategory/form`, element: <FormSubCategory /> }]
        //     : [{ path: `${master}/subcategory/form`, element: <SubCategory /> }]),

        // { path: `${master}/division`, element: <Division /> },
        // ...(myAddAuthIdx
        //     ? [{ path: `${master}/division/form`, element: <FormDivision /> }]
        //     : [{ path: `${master}/division/form`, element: <Division /> }]),

        // { path: `${master}/department`, element: <Department /> },
        // ...(myAddAuthIdx
        //     ? [{ path: `${master}/department/form`, element: <FormDepartment /> }]
        //     : [{ path: `${master}/department/form`, element: <Department /> }]),

        // { path: `${master}/section`, element: <Section /> },
        // ...(myAddAuthIdx
        //     ? [{ path: `${master}/section/form`, element: <FormSection /> }]
        //     : [{ path: `${master}/section/form`, element: <Section /> }]),

        // { path: `${master}/icon`, element: <IconMenu /> },
        // ...(myAddAuthIdx
        //     ? [{ path: `${master}/icon/form`, element: <FormIconMenu /> }]
        //     : [{ path: `${master}/icon/form`, element: <IconMenu /> }]),

        // { path: `${master}/employee`, element: <Employee /> },
        // ...(myAddAuthIdx
        //     ? [{ path: `${master}/employee/form`, element: <FormEmployee /> }]
        //     : [{ path: `${master}/employee/form`, element: <Employee /> }]),

        // { path: `${master}/schedule`, element: <Schedule /> },
        // ...(myAddAuthIdx
        //     ? [{ path: `${master}/schedule/form`, element: <FormSchedule /> }]
        //     : [{ path: `${master}/schedule/form`, element: <Schedule /> }]),

        // { path: `${master}/sensor`, element: <Sensor /> },
        // ...(myAddAuthIdx
        //     ? [{ path: `${master}/sensor/form`, element: <FormSensor /> }]
        //     : [{ path: `${master}/sensor/form`, element: <Sensor /> }]),

        // { path: `${master}/location`, element: <Location /> },
        // ...(myAddAuthIdx
        //     ? [{ path: `${master}/location/form`, element: <FormLocation /> }]
        //     : [{ path: `${master}/location/form`, element: <Location /> }]),

        // { path: `${master}/map`, element: <Map /> },
        // ...(myAddAuthIdx
        //     ? [{ path: `${master}/map/form`, element: <FormMap /> }]
        //     : [{ path: `${master}/map/form`, element: <Map /> }]),

        // { path: `${master}/mapping`, element: <Mapping /> },

        // { path: `${master}/part`, element: <Part /> },
        // ...(myAddAuthIdx
        //     ? [{ path: `${master}/part/form`, element: <FormPart /> }]
        //     : [{ path: `${master}/part/form`, element: <Part /> }]),

        // { path: `${master}/vehicle`, element: <Vehicle /> },
        // ...(myAddAuthIdx
        //     ? [{ path: `${master}/vehicle/form`, element: <FormVehivle /> }]
        //     : [{ path: `${master}/vehicle/form`, element: <Vehicle /> }]),

        // { path: `${master}/vendor`, element: <Vendor /> },
        // ...(myAddAuthIdx
        //     ? [{ path: `${master}/vendor/form`, element: <FormVendor /> }]
        //     : [{ path: `${master}/vendor/form`, element: <Vendor /> }]),

        // { path: `${master}/tran`, element: <Tran /> },
        // ...(myAddAuthIdx
        //     ? [{ path: `${master}/tran/form`, element: <FormTran /> }]
        //     : [{ path: `${master}/tran/form`, element: <Tran /> }]),

        // { path: `${master}/tranapprover`, element: <TranApprover /> },

        // { path: `${master}/user`, element: <User /> },
        // ...(myAddAuthIdx
        //     ? [{ path: `${master}/user/form`, element: <FormUser /> }]
        //     : [{ path: `${master}/user/form`, element: <User /> }]),

        // { path: `${master}/usergroup`, element: <UserGroup /> },
        // ...(myAddAuthIdx
        //     ? [{ path: `${master}/usergroup/form`, element: <FormUserGroup /> }]
        //     : [{ path: `${master}/usergroup/form`, element: <UserGroup /> }]),

        // { path: `${transaction}/worequest`, element: <WORequest /> },
        // ...(myAddAuthIdx
        //     ? [{ path: `${transaction}/worequest/form`, element: <FormWORequest /> }]
        //     : []),

        // { path: `${transaction}/workorder`, element: <WOTransaction /> },
        // ...(myAddAuthIdx
        //     ? [
        //         {
        //             path: `${transaction}/workorder/form`,
        //             element: <FormWOTransaction />,
        //         },
        //     ]
        //     : []),

        // { path: `${transaction}/pmconfiguration`, element: <PMTransaction /> },
        // ...(myAddAuthIdx
        //     ? [
        //         {
        //             path: `${transaction}/pmconfiguration/form`,
        //             element: <FormPMTransaction />,
        //         },
        //     ]
        //     : []),

        // { path: `${transaction}/demand`, element: <DMTransaction /> },
        // ...(myAddAuthIdx
        //     ? [
        //         {
        //             path: `${transaction}/demand/form`,
        //             element: <FormDMTransaction />,
        //         },
        //     ]
        //     : []),

        // { path: `${transaction}/mptransaction`, element: <MPTransaction /> },
        // ...(myAddAuthIdx
        //     ? [
        //         {
        //             path: `${transaction}/mptransaction/form`,
        //             element: <FormMPTransaction />,
        //         },
        //     ]
        //     : []),

        // { path: `${transaction}/tranApproval`, element: <TranApproval /> },


        // { path: `${transaction}/adjustment`, element: <AdjustmentTransaction /> },
        // { path: `${transaction}/adjustment/form`, element: <FormAdjustmentTransaction /> },

        // // REPORT
        // { path: `report/equipmenthistory`, element: <EquipmentHistory /> },
        // { path: `report/pmschedule`, element: <PMSchedule /> },
    ];

    return (
        <Routes>
            {mainRoutes.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
            ))}
        </Routes>
    );
};

export default RouteDashboard;
