import React, { useEffect, useRef, useState } from 'react';
import { tokens } from "../../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import { useTheme, Box, TextField, Autocomplete, Button } from "@mui/material";
import { StyleButtonForm, StyleFormInput } from '../../global/StyleCSS';
import { getTranApproval, getTranApproverAppStatus } from '../Api/GetData';
// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { updateTranApproval } from '../Api/UpdateData';
import { useSnackbar } from 'notistack';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import { getFacility } from '../../../Api/Master/GetData';

const FormTranApproval = ({ dataMP, dataPM, adjustment }) => {

    // console.log("dataMP", dataMP);
    // console.log("dataPM", dataPM);
    // console.log("adjustment", adjustment);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const formRef = useRef(null);

    const { user } = useSelector((state) => state.auth);

    const navigate = useNavigate();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const faccode = searchParams.get('faccode');
    const trannumber = searchParams.get('trannumber');

    const [TranApproval, setTranApproval] = useState([]);

    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const [approverData, setApproverData] = useState([]);
    const [selectedApproverData, setSelectedApproverData] = useState(null);
    const [OpenApprover, setOpenApprover] = useState(false);

    // const [AppDate, setAppDate] = useState(dayjs());

    const [facilityCode, setFacilityCode] = useState("");

    useEffect(() => {
        const fetchFacility = async () => {
            try {
                const res = await getFacility();
                const Codes = res.map(item => item.FacilityCode).join(",");
                setFacilityCode(Codes);
            } catch (error) {
                console.log(error);
            }
        };

        if (!user.FacilityAuthority.join(",")) {
            fetchFacility();
        } else {
            setFacilityCode(user.FacilityAuthority.join(","));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const fetchDataApproval = async () => {
            try {
                if (user && facilityCode) {
                    const response = await getTranApproval(facilityCode, user.UserName, '', trannumber);
                    setTranApproval(response[0]);                    
                }
            } catch (error) {
                console.log(error);
            }
        }

        fetchDataApproval();
    }, [facilityCode, trannumber, user]);

    useEffect(() => {
        if (faccode && trannumber && TranApproval.TranIdx) {
            if (TranApproval) {
                setOpenApprover(true);
                setSelectedApproverData(TranApproval.AppStatus);
            }
        }
    }, [TranApproval, faccode, trannumber]);

    useEffect(() => {
        const FetchDataApprover = async () => {
            setIsLoading(true);
            try {
                if (TranApproval.TranIdx && user) { // Tambahkan pengecekan TranIdx dan user
                    const resApprover = await getTranApproverAppStatus(TranApproval.TranIdx, '');
                    setApproverData(resApprover[0].ApproverLevel);
                }
            } catch (e) {
                setApproverData([]);
                console.log("eror code Approver", e);
            }
            setIsLoading(false);
        }
        if (OpenApprover) {
            FetchDataApprover();
            setOpenApprover(false);
        }

    }, [OpenApprover, TranApproval.TranIdx, user]);


    const handleApprover = (event, value) => {
        if (value) {
            setSelectedApproverData(value.Level);
        } else {
            setSelectedApproverData(null);
        }
    }
  

    const handleFormSubmit = async (values) => {
        setLoading(true);
        try {
            const modifiedValues = {
                ...values,
                FacilityCode: faccode,
                ApprovalNo: TranApproval.ApprovalNo,
                TranIdx: TranApproval.TranIdx,
                TranNumber: trannumber,
                TranDate: TranApproval.AppDate,
                Approver: user && '',
                AppStatus: selectedApproverData,
                AppDate: dayjs().format('YYYY-MM-DD'),
                ScheduleType: dataMP ? dataMP.PMNumber.PMTransaction.ScheduleType : dataPM ? dataPM.ScheduleType : '',
                PMNumber: dataMP ? dataMP.MPTransaction.PMNumber : dataPM ? dataPM.PMNumber : '',
                ScheduleCode: dataMP ? dataMP.MPTransaction.ScheduleCode : '',
                ScheduleStart: dataPM ? dataPM.ScheduleStart : dataMP ? dataMP.PMNumber.PMTransaction.ScheduleStart : null,
                ScheduleEnd: dataPM ? dataPM.ScheduleEnd : dataMP ? dataMP.PMNumber.PMTransaction.ScheduleEnd : null,
                BatchNo: 'ADN0001',
                // PartCode: '',
                // StartDate:  '',
                // FinishDate:  '',
                // Description: dataMP ? dataMP.MPTransaction.Description : '',
            }

            console.log("Submit Data", modifiedValues);

            const response = await updateTranApproval(faccode, TranApproval.TranIdx, TranApproval.ApprovalNo, trannumber, modifiedValues);
            enqueueSnackbar(response.data.statusMessage, { variant: "success" });
            navigate("/transaction/tranapproval");
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    const initialValues = {
        TranNumber: "",
        AppStatus: "",
        AppDate: "",
        Description: "",
    };

    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
            innerRef={formRef}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
            }) => (
                <Box sx={{
                    gap: "10px",
                    position: "sticky",
                    top: 0,
                    bottom: 0,
                    zIndex: 100,
                    width: "100%",
                    boxShadow: "0px -5px 10px #00000016",
                    backgroundColor: `${colors.grey[100]} !important`,
                }}>
                    <Box>
                        <Box sx={StyleFormInput}>

                            {/* <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Tran Number"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={trannumber}
                                name="TranNumber"
                                sx={{ gridColumn: "span 2" }}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Approver Date*"
                                    value={AppDate}
                                    onBlur={handleBlur}
                                    onChange={() => setAppDate(dayjs())}
                                    name="ApproverDate"
                                    sx={{ gridColumn: "span 2" }}
                                    readOnly={true}
                                />
                            </LocalizationProvider> */}

                            <Autocomplete
                                fullWidth
                                disablePortal
                                onOpen={() => setOpenApprover(true)}
                                loading={isLoading}
                                options={approverData}
                                getOptionLabel={(option) => option.Level}
                                sx={{ gridColumn: "span 2" }}
                                onChange={(event, value) => {
                                    handleApprover(event, value);
                                    setFieldValue("AppStatus", value ? value.Level : "");
                                }}
                                value={approverData.find((option) => option.Level === selectedApproverData) || null}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Approver Status*"
                                        onBlur={handleBlur}
                                        error={!!touched.AppStatus && !!errors.AppStatus}
                                        helperText={touched.AppStatus && errors.AppStatus}
                                    />
                                )}
                                noOptionsText={
                                    approverData.length === 0 ?
                                        <Link to={`/configuration/tranapprover?tranidx=${TranApproval.TranIdx}`} target="_blank" style={{ textDecoration: 'none' }}>there is no option, please fill in the tranApprover data</Link>
                                        : "No options"
                                }
                            />

                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Description"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.Description}
                                name="Description"
                                sx={{ gridColumn: "span 2" }}
                                multiline
                                rows={2}
                            />

                        </Box>
                    </Box>
                    <Box sx={StyleButtonForm}>
                        <Link to="/transaction/tranapproval" >
                            <Button
                                type="button"
                                color="error"
                                variant="contained"
                            >
                                <span>Cancel</span>
                            </Button>
                        </Link>
                        <LoadingButton
                            color="primary"
                            onClick={handleSubmit}
                            loading={loading}
                            variant="contained"
                        >
                            <span>Submit</span>
                        </LoadingButton>
                    </Box>
                </Box>
            )}
        </Formik>
    )
}

const checkoutSchema = yup.object().shape({
    AppStatus: yup
        .string()
        .required("Please complete this Approval Status."),
});

export default FormTranApproval;
