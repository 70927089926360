import React, { useEffect, useState } from 'react';
import { Form, Input, Popconfirm, Table, Typography, InputNumber } from 'antd';
import { CloseOutlined, DeleteOutlined, EditFilled, SaveFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { LoadingButton } from "@mui/lab"
import { Button } from '@mui/material';
import { getDMPartTrasaction } from '../../Api/GetData';
import { getPart } from '../../../../Api/Master/GetData';
import InputDetailModal from '../../../../components/Global/InputDetailModal';

const { TextArea } = Input;
const { Title } = Typography;


const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    onData,
    onDataPart,
    onEdit,
    ...restProps
}) => {

    useEffect(() => {
        if (onEdit) {
            setOpenPart(true);
        }
    }, [onEdit]);

    const [isLoading, setIsLoading] = useState(true);

    const [dataPart, setDataPart] = useState([]);
    const [selectPart, setSelectPart] = useState("");
    const [openPart, setOpenPart] = useState(null);

    useEffect(() => {
        const fetchPart = async () => {
            try {

                const PartCode = onData.map(item => item.PartCode);

                const res = await getPart();
                const filter = res.filter(item => !PartCode.includes(item.Part.PartCode));
                setDataPart(filter);
            } catch (error) {
                console.log(error);
            }
        }

        if (openPart) {
            fetchPart();
            setOpenPart(false);
            setIsLoading();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openPart]);

    useEffect(() => {
        if (selectPart) {
            onDataPart(selectPart);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectPart]);


    return (
        <td {...restProps}>
            {editing ? (
                <div>

                    {dataIndex === 'Description' ? (
                        <Form.Item
                            name={dataIndex}
                            style={{
                                margin: 0,
                            }}
                        >

                            <TextArea rows={4} placeholder={title} />
                        </Form.Item>

                    ) : (

                        <Form.Item
                            name={dataIndex}
                            style={{
                                margin: 0,
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: `Please Input ${title}!`,
                                },
                            ]}
                        >
                            {dataIndex === 'PartName' && editing ? (
                                // <Select
                                //     maxTagCount="responsive"
                                //     showSearch
                                //     placeholder={title}
                                //     onDropdownVisibleChange={fetchPart}
                                //     notFoundContent={
                                //         <Link to={`/master/part/form`} target="_blank" style={{ textDecoration: 'none' }}>
                                //             There is no option, click to fill in part data
                                //         </Link>
                                //     }
                                // >
                                //     {PartData.map((item) => (
                                //         <Option key={item.key} value={item.Part.PartName}>
                                //             {item.Part.PartName}
                                //         </Option>
                                //     ))}
                                // </Select>

                                <InputDetailModal
                                    title="PART"
                                    label="Part"
                                    name={dataIndex}
                                    dataSource={dataPart}
                                    loading={isLoading}
                                    columns={columnsPart}
                                    onData={(values) => setSelectPart(values)}
                                    onOpenModal={(values) => setOpenPart(values)}
                                    onDetail={true}
                                />

                            ) : (
                                dataIndex === 'QTY' ? (
                                    <InputNumber placeholder={title} min={1} style={{ width: '100%' }} />
                                ) : (

                                    <Input placeholder={title} />
                                )
                            )}
                        </Form.Item>
                    )}

                </div>
            ) : (
                children
            )
            }
        </td >
    );
};


const FormDMPart = ({ onSaveData, FacilityCode, DMNumber, onEdit, onApproval }) => {

    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);

    const [editingKey, setEditingKey] = useState('');
    const [loading, setLoading] = useState(false);
    const [isDisable, setIsDisable] = useState(true);

    const [dataPart, setDataPart] = useState(null);

    useEffect(() => {
        if (form && dataPart) {
            form.setFieldsValue({
                PartName: dataPart.Part.PartName
            })
        }
    }, [dataPart, form]);

    useEffect(() => {
        if (onEdit || onApproval) {
            const fetchData = async () => {
                try {
                    const response = await getDMPartTrasaction();
                    const filter = response.filter((item) =>
                        item.FacilityCode === FacilityCode &&
                        item.DMNumber === DMNumber
                    ).map((row, index) => ({
                        ...row, key: index + 1
                    })).reverse();
                    setData(filter)
                    setCount(filter.length === 0 ? 0 : filter.map((item) => item.key)[0])
                    onSaveData(filter)
                } catch (error) {
                    setData([]);
                    setCount(0);
                    onSaveData([]);
                    console.log(error);
                }
            }
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [DMNumber, FacilityCode, onEdit]);


    const isEditing = (record) => record.key === editingKey;

    const handleEdit = (record) => {

        const PartData = record.PartName ? record.PartName : [];

        form.setFieldsValue({
            Description: '',
            ...record,
            PartName: PartData,
        });
        setEditingKey(record.key);

        // console.log("DataFormTran", data);
    };


    const handleDelete = (key) => {
        setIsDisable(false);
        const deletedRow = data.find((row) => row.key === key);
        const deletedNumber = deletedRow.key;
        const deletedkey = deletedRow.key;

        const newData = data.filter((row) => row.key !== key);

        const updatedData = newData.map((row) => {

            if (row.key > deletedNumber && row.key > deletedkey) {
                return { ...row, key: row.key - 1 };
            }
            return row;
        });

        setCount(updatedData.length > 0 ? updatedData[0].key : 0);

        setData(updatedData);
        onSaveData(updatedData);

        // console.log("DataFormTran", updatedData);
    };


    const handleCancel = (record) => {
        if (!record.PartName) {
            const newData = data.filter((item) => item.key !== record.key);
            setData(newData);
        } else {
            setEditingKey('');
        }
        setEditingKey('');

        // console.log("DataFormTran", data);
    };


    const handleSave = async (record) => {
        setIsDisable(false);
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => record.key === item.key);
            const PartCode = dataPart.Part.PartCode;

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                    PartCode: PartCode
                });
                setData(newData);
                setEditingKey('');
                console.log("DataFormTran", newData);
                onSaveData(newData)
            } else {
                newData.push({
                    ...row,
                    PartCode: PartCode
                });
                setData(newData);
                setEditingKey('');
                console.log("DataFormTran", newData);
                onSaveData(newData)
            }

            const editedRow = data.find((row) => row.key === record.key);
            const lastNumber = editedRow.key;

            setCount(lastNumber);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };



    const handleAdd = () => {

        const num = count + 1;

        if (editingKey) {
            enqueueSnackbar("Complete the input form !", { variant: "warning" });
            return; // Stop saving if duplicate found
        }

        const newData = {
            key: num,
            FacilityCode: FacilityCode,
            DMNumber: DMNumber,
            DetailNo: num,
            PartCode: '',
            PartName: '',
            QTY: '',
            Description: '',
        };
        setData([newData, ...data]);
        handleEdit(newData);

        // console.log("DataFormTran", data);
    };

    const handleSaveAllData = async () => {
        setLoading(true);
        setIsDisable(true);
        try {
            onSaveData(data);
            console.log("PostData", data);
            enqueueSnackbar("Success add form table data!!", { variant: "success" });
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    const handleCancelAllData = () => {
        setData([]);
        setCount(0);
        onSaveData([]);
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'key',
            sorter: (a, b) => a.key - b.key,
        },
        {
            title: 'Part Name',
            dataIndex: 'PartName',
            editable: true,
        },
        {
            title: 'QTY',
            dataIndex: 'QTY',
            editable: true,
        },
        {
            title: 'Description',
            dataIndex: 'Description',
            editable: true,
        },
    ];

    if (!onApproval) {
        columns.push({
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
                        <Typography.Link onClick={() => handleSave(record)} style={{ fontSize: '18px' }}>
                            <SaveFilled />
                        </Typography.Link>

                        <Typography.Link onClick={() => handleCancel(record)} style={{ fontSize: '18px' }}>
                            <CloseOutlined />
                        </Typography.Link>
                    </span>
                ) : (
                    <span style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
                        <Typography.Link onClick={() => handleEdit(record)} style={{ fontSize: '18px' }}>
                            <EditFilled />
                        </Typography.Link>
                        <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
                            <Link>
                                <DeleteOutlined style={{ fontSize: '18px' }} />
                            </Link>
                        </Popconfirm>
                    </span>
                );
            },
        },)
    }


    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            onCell: (record) => ({
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                onData: data,
                onDataPart: (values) => setDataPart(values),
                onEdit: onEdit,
            }),
            ...col,
        };
    });

    return (
        <Form form={form} component={false}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    margin: "0 10px 10px"
                }}
            >
                <Title level={3} style={{ margin: "0" }}>
                    Part
                </Title>
                {!onApproval && (
                    <Button
                        onClick={handleAdd}
                        color="primary"
                        variant="contained"
                        disabled={!!editingKey || !FacilityCode || !DMNumber}
                    >
                        + Add Data
                    </Button>
                )}
            </div>
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                // bordered
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                    onChange: handleCancel,
                    pageSize: 5,
                }}
            />
            {!onApproval && (
                <div
                    style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "flex-end",
                        margin: "10px 10px 0"
                    }}
                >
                    <Popconfirm title="Sure to clear all data?" onConfirm={handleCancelAllData}>
                        <Button
                            type="button"
                            color="error"
                            variant="contained"
                            disabled={!!editingKey || !!isDisable}
                        >
                            <span>Cancel</span>
                        </Button>
                    </Popconfirm>
                    <LoadingButton
                        color="primary"
                        onClick={handleSaveAllData}
                        loading={loading}
                        variant="contained"
                        disabled={!!editingKey || !!isDisable}
                    >
                        <span>Save</span>
                    </LoadingButton>

                </div>
            )}
        </Form>
    );
};
export default FormDMPart;


const columnsPart = [
    {
        key: 'key',
        title: 'No',
        dataIndex: 'key',
        width: 80,
        fixed: 'left',
        sorter: (a, b) => a.key - b.key,
    },
    {
        key: 'PartCode',
        title: 'Part Code',
        dataIndex: ['Part', 'PartCode'],
        sorter: (a, b) => a.Part.PartCode.localeCompare(b.Part.PartCode),
    },
    {
        key: 'PartName',
        title: 'Part Name',
        dataIndex: ['Part', 'PartName'],
        sorter: (a, b) => a.Part.PartName.localeCompare(b.Part.PartName),
    },
    {
        key: 'PartCategory',
        title: 'Part Category',
        dataIndex: 'CategoryName',
        sorter: (a, b) => a.CategoryName.localeCompare(b.CategoryName),
    },
    {
        key: 'MinStock',
        title: 'Minum Stock',
        dataIndex: ['Part', 'MinStock'],
        sorter: (a, b) => a.Part.MinStock.localeCompare(b.Part.MinStock),
    },
    {
        key: 'UnitName',
        title: 'Unit Name',
        dataIndex: 'UnitName',
        sorter: (a, b) => a.UnitName.localeCompare(b.UnitName),
    }
]
