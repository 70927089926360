import React, { useState, useEffect, useRef, useContext } from "react";
import Header from "../../../components/Header";
import Authentication from "../../auth";
import { Table, Form, InputNumber } from "antd";
import SearchData from "../../global/components/FeatureTable/SearchData";
import TableForm from "./table";
import { enqueueSnackbar } from "notistack";
import StyleContent from "../../../components/Global/StyleContent";
import StyleGridHeader from "../../../components/Global/StyleContent/StyleGrid/StyleGridHeader";
import { useLocation } from "react-router-dom";
import { updateTranAppMap } from "../../../Api/Configuration/UpdateData";
import { getTranAppMap } from "../../../Api/Configuration/GetData";

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });

      const AppLevelUpdate = values.AppLevel.toString();
      const modifiedValues = {
        ...record,
        AppLevel: AppLevelUpdate
      }
      await updateTranAppMap(record.MTranIdx, record.TranIdx, modifiedValues);
      enqueueSnackbar("Success updated data App Level!!", { variant: "success" });

    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <InputNumber min={1} max={9} ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const TranApprover = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tranIdx = searchParams.get('tranidx');

  Authentication();

  const [selectedTranIdx, setSelectedTranIdx] = useState(null);
  const [selectedApproverLevel, setSelectedApproverLevel] = useState(null);
  const [filterValue, setFilterValue] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const [data, setData] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const expandedRowRender = () => {
    return (
      <>
        <TableForm
          tranIdx={selectedTranIdx}
          approverLevel={selectedApproverLevel}
        />
      </>
    );
  };

  const handleRowExpand = (expanded, record) => {
    if (expanded) {
      setSelectedTranIdx(record.TranIdx);
      setSelectedApproverLevel(record.AppLevel);
      setExpandedRowKeys([record.key]);
    } else {
      setExpandedRowKeys([]);
    }
  };

  const fetchData = async () => {
    try {
      const response = await getTranAppMap();
      const filterRes = response.filter((item => item.TranIdx !== '1401'));
      setData(filterRes);
      if (tranIdx) {
        const selectedRow = filterRes.find(row => row.TranIdx === tranIdx);
        if (selectedRow) {
          setExpandedRowKeys([selectedRow.key]);
          setSelectedTranIdx(selectedRow.TranIdx);
          setSelectedApproverLevel(selectedRow.AppLevel);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultColumns = [
    // {
    //   key: 'key',
    //   title: 'No',
    //   dataIndex: 'key',
    //   width: 50,
    //   sorter: (a, b) => a.key - b.key,
    // },
    {
      title: 'Tran Index',
      dataIndex: 'TranIdx',
      width: 150,
      sorter: (a, b) => a.TranIdx.localeCompare(b.TranIdx),
    },
    {
      title: 'Tran Name',
      dataIndex: 'TranName',
      width: 150,
      sorter: (a, b) => a.TranName.localeCompare(b.TranName),
    },
    {
      title: 'Approver Level',
      dataIndex: 'AppLevel',
      width: 150,
      sorter: (a, b) => a.AppLevel.localeCompare(b.AppLevel),
      editable: true,
    },
  ];

  const handleSave = (row) => {
    const newData = [...data];
    const index = newData.findIndex((item) => row.TranIdx === item.TranIdx);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setData(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const handleFilterChange = (event) => {
    const value = event.target.value;
    setFilterValue(value);

    const filteredData = data.filter((row) => {
      return (
        row.TranIdx.toLowerCase().includes(value.toLowerCase()) ||
        row.TranName.toLowerCase().includes(value.toLowerCase()) ||
        row.AppLevel.toLowerCase().includes(value.toLowerCase())
      );
    });

    setFilteredRows(filteredData);
  };

  const [selectedRows, setSelectedRows] = useState([]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
  };

  const columnsExport = [
    { title: "key", key: "key" },
    { title: "Facility Code", key: "FacilityCode" },
    { title: "Tran Index", key: "TranIdx" },
    { title: "Approver Code", key: "ApproverCode" },
    { title: "Approver Level", key: "ApproverLevel" }
  ];

  return (
    <StyleContent>
      <StyleGridHeader>
        <Header title="APPROVER" subtitle="All Approver Data" />
      </StyleGridHeader>

      <SearchData
        filterValue={filterValue}
        handleFilterChange={handleFilterChange}
      />

      <Table
        columns={columns}
        expandable={{
          expandedRowRender,
          onExpand: handleRowExpand,
          expandedRowKeys,
        }}
        components={components}
        rowClassName={() => 'editable-row'}
        dataSource={data}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        scroll={{
          x:1000
      }}
      />
    </StyleContent>
  );
};

export default TranApprover;
